import {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useMutation} from 'graphql/client';
import {Autocomplete, Box, Typography, Button, TextField} from 'components/mui';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import {Notification} from 'core/Notification/index.ts';
import StyledLinearProgress from 'components/ui/graphics/StyledLinearProgress';
import UPDATE_AICP from 'graphql/pages/aicp/updateAicp.graphql';
import AICPS from 'graphql/pages/aicps/Aicps.graphql';

const UPDATE_AICP_TAGS_ERROR_MESSAGE =
    'Failed to update aiCP tags. Please wait a moment and try again.';
const TAGS_INPUT_ERROR_MESSAGE =
    'Tag includes restricted characters. Please only use alphanumeric characters, hypens, and spaces.';

const MenuWrapper = styled(Box)`
    width: 450px;
`;

const StyledHr = styled.hr`
    margin-top: 0px;
    margin-bottom: 0px;
`;

const IconWrapper = styled(Box)`
    color: ${({theme}) => theme.palette.action.disabled};
    .AddIcon {
        cursor: pointer;
        &--disabled {
            cursor: default;
        }
    }
`;

const UpdateAicpTagsMenu = ({
    aicpId,
    handleClose,
    setIsOpen,
    existingAicpTags = [],
    clientOrgTags = [],
}) => {
    const [selectedTags, setSelectedTags] = useState(existingAicpTags);
    const [textfieldError, setTextfieldError] = useState(false);
    const [inputText, setInputText] = useState('');

    const [updateAicp, {loading: updateAicpLoading}] = useMutation(
        UPDATE_AICP,
        {
            onCompleted(data) {
                const success = data?.updateAicp?.status?.success || false;
                if (success) {
                    return setIsOpen(false);
                }
                Notification.error(UPDATE_AICP_TAGS_ERROR_MESSAGE);
            },

            onError() {
                Notification.error(UPDATE_AICP_TAGS_ERROR_MESSAGE);
            },
        }
    );

    const submitAicp = () => {
        try {
            updateAicp({
                variables: {['aicpId']: aicpId, ['tags']: selectedTags},
                refetchQueries: [{query: AICPS}],
                awaitRefetchQueries: true,
            });
        } catch (e) {
            Notification.error(UPDATE_AICP_TAGS_ERROR_MESSAGE);
        }
    };

    const onChange = (event, newTags) => {
        const allowableChars = /^[a-z0-9\s-]*$/gi;
        const newTag = newTags[newTags.length - 1];
        if (allowableChars.test(newTag)) {
            const lowercaseTags = newTags.map((tag) => tag.toLowerCase());
            setSelectedTags(lowercaseTags);
            setTextfieldError(false);
            return;
        }
        setTextfieldError(true);
        return;
    };

    const existingAicpTagsObject = existingAicpTags.reduce(
        (acc, tag) => ({...acc, [tag]: tag}),
        {}
    );
    const tagsAreIdentical = selectedTags.every(
        (tag) => tag in existingAicpTagsObject
    );

    const submitButtonDisabled =
        tagsAreIdentical && selectedTags.length === existingAicpTags.length;

    return (
        <MenuWrapper data-testid="add-aicp-tags-menu">
            {updateAicpLoading && (
                <StyledLinearProgress
                    width="100%"
                    position="absolute"
                    top={0}
                />
            )}
            <Box
                p={3}
                pr={3}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography data-testid="menu-header" variant="h6">
                    {existingAicpTags.length ? 'Edit Tags' : 'Add Tags'}
                </Typography>
                <Button data-testid="close-btn" onClick={handleClose}>
                    Cancel
                </Button>
            </Box>
            <StyledHr />
            <Box
                p={2.5}
                pr={3}
                pb={1.25}
                display="flex"
                flexDirection="column"
                gap="20px"
                component="form"
                onSubmit={(e) => {
                    e.preventDefault();
                    submitAicp();
                }}
            >
                <Box display="flex" width="100%">
                    <Box flexGrow="1">
                        <Autocomplete
                            multiple
                            options={clientOrgTags}
                            autoSelect
                            freeSolo={true}
                            value={selectedTags}
                            onChange={onChange}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        data-testid="tags-input"
                                        label="Enter tags"
                                        variant="outlined"
                                        error={textfieldError}
                                        helperText={
                                            textfieldError &&
                                            TAGS_INPUT_ERROR_MESSAGE
                                        }
                                        inputProps={{
                                            ...params.inputProps,
                                            'data-hj-allow': true,
                                            onChange: (event) => {
                                                setInputText(
                                                    event.target.value
                                                );
                                                params.inputProps.onChange(
                                                    event
                                                );
                                            },
                                            onBlur: (event) => {
                                                setInputText('');
                                                params.inputProps.onBlur(event);
                                            },
                                        }}
                                    />
                                );
                            }}
                        />
                    </Box>
                    <IconWrapper
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="64px"
                    >
                        <AddIcon
                            data-testid="add-tags-icon"
                            className={`AddIcon${
                                inputText ? '' : ' AddIcon--disabled'
                            }`}
                            cursor="pointer"
                            fontSize="small"
                            color={`${inputText ? 'primary' : 'inherit'}`}
                        />
                    </IconWrapper>
                </Box>
                <Box mb={2} display="flex" alignSelf="end">
                    <Button
                        data-testid="submit-btn"
                        type="submit"
                        disabled={submitButtonDisabled}
                    >
                        Save Tags
                    </Button>
                </Box>
            </Box>
        </MenuWrapper>
    );
};

UpdateAicpTagsMenu.propTypes = {
    aicpId: PropTypes.string.isRequired,
    existingAicpTags: PropTypes.arrayOf(PropTypes.string),
    clientOrgTags: PropTypes.arrayOf(PropTypes.string),
    handleClose: PropTypes.func.isRequired,
    setIsOpen: PropTypes.func.isRequired,
};

export {UpdateAicpTagsMenu, UPDATE_AICP_TAGS_ERROR_MESSAGE};
