import AccountFilters from 'core/Aicp/AccountFilters';
import type {SelectedExegraphic} from 'core/ExegraphicLens';
import {Industry, IndustryType} from 'core/Aicp/types';

const getExegraphicLensNamesFromFilters = (
    filters: AccountFilters
): string[] => {
    return (
        filters?.exegraphicSignals?.map(
            (lens: SelectedExegraphic) => lens.lensName
        ) || []
    );
};

const getCustomExegraphicLensIdsFromFilters = (
    filters: AccountFilters
): string[] => {
    return (
        filters?.customExegraphicSignals?.map(
            (lens: SelectedExegraphic) => lens.id
        ) || []
    );
};

const checkIfFilteredByOutdatedIndustries = (industries = []) => {
    if (industries?.length > 0) {
        return !industries.find(
            (industry: Industry) =>
                industry.type?.toUpperCase() === IndustryType.RIC_5
        );
    }
    return false;
};

export {
    checkIfFilteredByOutdatedIndustries,
    getExegraphicLensNamesFromFilters,
    getCustomExegraphicLensIdsFromFilters,
};
