import {ChangeEvent, Dispatch, useReducer} from 'react';
import {DropdownOption} from 'components/types';
import {SelectChangeEvent} from 'components/mui';
import {UploadedFileData} from './FileUploadProvider';

const missingDataErrorMessage =
    'the action provided to the dispatch function must contain the data property';
const missingFieldNameErrorMessage =
    'the action provided to the dispatch function must contain a fieldName or fieldNames property';

export type FieldData = {
    isReadyToValidate?: boolean;
    validationRules?: string;
    isRequired?: boolean;
    value?: string | boolean | DropdownOption[] | UploadedFileData;
};

export type FormState = {
    [fieldName: string]: FieldData;
};

export type FormStateAction = {
    fieldName?: string;
    fieldNames?: string[];
    data: FieldData;
    error?: string;
};

const formStateReducer = (
    state: FormState,
    action: FormStateAction
): FormState => {
    if (action.fieldName) {
        const currentFieldData = state[action.fieldName];
        return {
            ...state,
            [action.fieldName]: {...currentFieldData, ...action.data},
        };
    }

    if (action.fieldNames) {
        return action.fieldNames.reduce((state, fieldName) => {
            const currentFieldData = state[fieldName];
            return {
                ...state,
                [fieldName]: {...currentFieldData, ...action.data},
            };
        }, state);
    }

    return state;
};

const isFieldValueTrimmable = (state, fieldName) => {
    return typeof state[fieldName]?.value?.trim === 'function';
};

export interface FormStateApi {
    initialFields: FormState;
    onCheckboxChange: (
        fieldName: string
    ) => (event: ChangeEvent<{checked: boolean}>) => void;
    onInputChange: (
        fieldName: string
    ) => (
        event:
            | ChangeEvent<{value: string}>
            | SelectChangeEvent<string | boolean | DropdownOption[]>
    ) => void;
    onPhoneInputChange: (
        fieldName: string
    ) => (event: ChangeEvent<{value: string}>) => void;
    setFieldValue: (
        fieldName: string,
        value: string | DropdownOption[]
    ) => void;
    setFieldIsReadyToValidate: (fieldName: string) => void;
    setFieldsAreReadyToValidate: (fieldNames: string[]) => void;
    normalizeFieldsOnSubmit: () => void;
    updateMultiSelectValue: (
        fieldName: string,
        value: string | DropdownOption[]
    ) => void;
    isMultiSelectOptionSelected: (
        option: FieldData,
        value: FieldData
    ) => boolean;
    getSelectedOptionsIdsList: (
        selectedOptionsList: {value: string}[]
    ) => string[];
    getFieldValue: (fieldName: string) => FieldData['value'];
    setFieldData: Dispatch<FormStateAction>;
    formState: FormState;
}

export interface FormStateProviderProps {
    initialFields: FormState;
    children: (formStateApi: FormStateApi) => JSX.Element;
}

const FormStateProvider = ({
    initialFields = {},
    children,
}: FormStateProviderProps): JSX.Element => {
    const [state, dispatch] = useReducer(formStateReducer, initialFields);

    const setFieldIsReadyToValidate: FormStateApi['setFieldIsReadyToValidate'] =
        (fieldName) => {
            dispatch({fieldName, data: {isReadyToValidate: true}});
        };

    const setFieldsAreReadyToValidate: FormStateApi['setFieldsAreReadyToValidate'] =
        (fieldNames = []) => {
            dispatch({fieldNames, data: {isReadyToValidate: true}});
        };

    const onInputChange: FormStateApi['onInputChange'] =
        (fieldName) => (event) => {
            dispatch({fieldName, data: {value: event.target.value}});
        };

    const onCheckboxChange: FormStateApi['onCheckboxChange'] =
        (fieldName) => (event) => {
            dispatch({fieldName, data: {value: event.target.checked}});
        };

    const setFieldValue: FormStateApi['setFieldValue'] = (
        fieldName,
        value: string | DropdownOption[]
    ) => {
        dispatch({fieldName, data: {value}});
    };

    const updateMultiSelectValue: FormStateApi['updateMultiSelectValue'] = (
        fieldName,
        value: string | DropdownOption[]
    ) => {
        setFieldValue(fieldName, value);
    };

    const onPhoneInputChange: FormStateApi['onPhoneInputChange'] =
        (fieldName: string) => (event: ChangeEvent<{value: string}>) => {
            const match = event.target.value
                .replace(/\D/g, '')
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            const value = match[2]
                ? `(${match[1]}) ${match[2]}${match[3] ? `-${match[3]}` : ''}`
                : match[1];

            dispatch({fieldName, data: {value}});
        };

    const isMultiSelectOptionSelected: FormStateApi['isMultiSelectOptionSelected'] =
        (option, value) => option.value === value.value;

    const getSelectedOptionsIdsList: FormStateApi['getSelectedOptionsIdsList'] =
        (selectedOptionsList: {value: string}[]) => {
            if (Array.isArray(selectedOptionsList)) {
                return selectedOptionsList.map((option) => option.value);
            }
        };

    const normalizeFieldsOnSubmit: FormStateApi['normalizeFieldsOnSubmit'] =
        () => {
            Object.keys(initialFields).every((fieldName) => {
                if (isFieldValueTrimmable(state, fieldName)) {
                    const value = state[fieldName].value as string;
                    setFieldValue(fieldName, value.trim());
                }
                return state;
            });
        };

    const getFieldValue: FormStateApi['getFieldValue'] = (fieldName) => {
        return state[fieldName].value;
    };

    return children({
        initialFields,
        onCheckboxChange,
        onInputChange,
        onPhoneInputChange,
        setFieldValue,
        setFieldIsReadyToValidate,
        setFieldsAreReadyToValidate,
        normalizeFieldsOnSubmit,
        updateMultiSelectValue,
        isMultiSelectOptionSelected,
        getSelectedOptionsIdsList,
        getFieldValue,
        setFieldData: dispatch,
        formState: state,
    });
};

export {
    FormStateProvider,
    missingDataErrorMessage,
    missingFieldNameErrorMessage,
};
