import {ResponseStatus} from 'core/GraphQL/types';
import {useMutation} from 'graphql/client';
import AICP from 'graphql/pages/aicp/aicp.graphql';
import REMOVE_ACCOUNT_SUPPRESSION_LIST_FROM_ICP from 'graphql/pages/aicp/removeAccountSuppressionListFromIcp.graphql';

export type RemoveAccountSuppressionListFromIcpData = {
    status: ResponseStatus;
};

export type RemoveAccountSuppressionListFromIcpVariables = {
    icpId: string;
    accountSuppressionListId: string;
};

export default function useRemoveAccountSuppressionListFromIcp(
    aicpId: string,
    onSuccessCallback: (isSuccess: boolean) => void,
    onErrorCallback: () => void
) {
    const [executeMutation, {loading}] = useMutation<
        RemoveAccountSuppressionListFromIcpData,
        RemoveAccountSuppressionListFromIcpVariables
    >(REMOVE_ACCOUNT_SUPPRESSION_LIST_FROM_ICP, {
        refetchQueries: [
            {query: AICP, variables: {idealCustomerProfileId: aicpId}},
        ],
        onError() {
            if (onErrorCallback) onErrorCallback();
        },
        onCompleted() {
            if (onSuccessCallback) onSuccessCallback(true);
        },
    });

    const removeAccountSuppressionListFromIcp = (
        accountSuppressionListId: string
    ) => {
        return executeMutation({
            variables: {
                icpId: aicpId,
                accountSuppressionListId: accountSuppressionListId,
            },
        });
    };

    return {
        removeAccountSuppressionListFromIcp,
        loading,
    };
}
