export const comparisonListFormFields = {
    fileName: {
        value: '',
        validationRules: 'text',
        isRequired: true,
    },
    comparisonFile: {
        value: null,
        validationRules: 'file',
        isRequired: true,
    },
};

export const suppressionFormFields = {
    suppressionListFile: {
        value: null,
        validationRules: 'file',
        isRequired: true,
    },
    company: {
        value: 'select',
        validationRules: 'select',
    },
};
