import CloseIcon from '@mui/icons-material/Close';
import {
    Breakpoint,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from 'components/mui';
import StyledLinearProgress from 'components/ui/graphics/StyledLinearProgress';
import {PropsWithChildren} from 'react';

export interface ConfirmationDialogProps {
    isOpen: boolean;
    onConfirm: (e?: React.MouseEvent<HTMLButtonElement>) => void;
    closeDialog: () => void;
    confirmButtonText?: string;
    isConfirmButtonAutofocused?: boolean;
    isConfirmButtonDisabled?: boolean;
    cancelButtonText?: string;
    titleText?: string;
    isCloseButtonDisplayed?: boolean;
    isCancelButtonDisplayed?: boolean;
    maxWidth?: Breakpoint | false;
    isLoading?: boolean;
}

const ConfirmationDialog = ({
    isOpen,
    onConfirm,
    closeDialog,
    confirmButtonText = 'Confirm',
    isConfirmButtonAutofocused = true,
    isConfirmButtonDisabled = false,
    cancelButtonText = 'Cancel',
    titleText,
    children,
    isCloseButtonDisplayed = true,
    isCancelButtonDisplayed = true,
    maxWidth,
    isLoading = false,
}: PropsWithChildren<ConfirmationDialogProps>) => {
    return (
        <Dialog
            open={isOpen}
            onClose={closeDialog}
            maxWidth={maxWidth}
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {isLoading && (
                <StyledLinearProgress
                    width="100%"
                    position="absolute"
                    top={0}
                />
            )}

            {titleText && (
                <DialogTitle id="alert-dialog-title" mr={4}>
                    {titleText}
                </DialogTitle>
            )}

            {isCloseButtonDisplayed && (
                <IconButton
                    aria-label="close"
                    size="small"
                    color="secondary"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                    onClick={closeDialog}
                >
                    <CloseIcon />
                </IconButton>
            )}

            <DialogContent>
                {children && (
                    <DialogContentText
                        id="alert-dialog-description"
                        component="div"
                    >
                        {children}
                    </DialogContentText>
                )}
            </DialogContent>

            <DialogActions sx={{m: 1}}>
                {isCancelButtonDisplayed && (
                    <Button
                        type="button"
                        variant="outlined"
                        onClick={closeDialog}
                    >
                        {cancelButtonText}
                    </Button>
                )}

                <Button
                    autoFocus={isConfirmButtonAutofocused}
                    disabled={isConfirmButtonDisabled}
                    aria-disabled={isConfirmButtonDisabled}
                    onClick={(e?: React.MouseEvent<HTMLButtonElement>) => {
                        if (onConfirm) onConfirm(e);
                        if (closeDialog) closeDialog();
                    }}
                >
                    {confirmButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
