import PropTypes from 'prop-types';
import React, {useState} from 'react';
import styled from 'styled-components';
import {useMutation} from 'graphql/client';
import {
    Box,
    Button,
    CircularProgress,
    Typography,
    TextField,
} from 'components/mui';

import {Notification} from 'core/Notification/index.ts';
import AICPS from 'graphql/pages/aicps/Aicps.graphql';
import ASSIGNED_ACCOUNTS from 'graphql/user/queries/assignedAccounts.graphql';
import PROSPECT_FILTER_OPTIONS from 'graphql/pages/prospects/prospectFilterOptions.graphql';
import UPDATE_AICP from 'graphql/pages/aicp/updateAicp.graphql';

export const UPDATE_AICP_NAME_ERROR_MESSAGE =
    'An error occurred while trying to update the aiCP.';
const UPDATE_AICP_NAME_SUCCESS_MESSAGE = 'Successfully changed aiCP name.';

const EditAicpMenu = styled.div`
    width: 480px;
`;

const StyledHr = styled.hr`
    margin-top: 0px;
    margin-bottom: 0px;
`;

const EditAicpName = ({handleClose, setIsOpen, aicpName, aicpId}) => {
    const [aicpNameState, setAicpNameState] = useState(aicpName);
    const [updateAicp, {loading: isUpdateAicpLoading}] = useMutation(
        UPDATE_AICP,
        {
            refetchQueries: [
                {query: AICPS},
                {query: ASSIGNED_ACCOUNTS},
                {query: PROSPECT_FILTER_OPTIONS},
            ],
            onCompleted(data) {
                Notification.success(UPDATE_AICP_NAME_SUCCESS_MESSAGE, {
                    removeAfter: 5,
                });
                setIsOpen(false);
            },
            onError(error) {
                Notification.error(UPDATE_AICP_NAME_ERROR_MESSAGE);
                setIsOpen(false);
            },
        }
    );

    return (
        <EditAicpMenu data-testid="edit-aicp-name-menu">
            <Box
                padding={3}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h6" component="h6">
                    Edit Name
                </Typography>
                <Button
                    data-testid="cancel-btn"
                    onClick={handleClose}
                    disabled={isUpdateAicpLoading}
                >
                    Cancel
                </Button>
            </Box>
            <StyledHr />
            <Box
                padding={3}
                display="flex"
                flexDirection="column"
                gap="20px"
                component="form"
            >
                <TextField
                    data-testid="name-input"
                    fullWidth
                    type="text"
                    variant="outlined"
                    defaultValue={aicpNameState}
                    onChange={(e) => {
                        setAicpNameState(e.target.value);
                    }}
                    inputProps={{
                        'data-hj-allow': true,
                    }}
                />
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        data-testid="update-aicp-name-btn"
                        size="medium"
                        onClick={() => {
                            updateAicp({
                                variables: {
                                    aicpId: aicpId,
                                    name: aicpNameState,
                                },
                            });
                        }}
                        disabled={
                            isUpdateAicpLoading ||
                            aicpNameState === aicpName ||
                            !aicpNameState.length > 0
                        }
                    >
                        {isUpdateAicpLoading && (
                            <>
                                <CircularProgress
                                    data-testid="loading-icon"
                                    color="inherit"
                                    size={18}
                                />
                                &nbsp;
                            </>
                        )}
                        Update
                    </Button>
                </Box>
            </Box>
        </EditAicpMenu>
    );
};
EditAicpName.propTypes = {
    aicpName: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    aicpId: PropTypes.string.isRequired,
};

export default EditAicpName;
