import {MenuItem, Typography} from 'components/mui';
import StyledLinearProgress from 'components/ui/graphics/StyledLinearProgress';
import ComparisonListContent from 'components/ui/pages/aicps/actions-menu/comparison-list-content/ComparisonListContent';
import SuppressionContent from 'components/ui/pages/aicps/actions-menu/suppression-content/SuppressionContent';
import {AICP_STATUS} from 'components/ui/pages/aicps/statuses.js';
import StyledActionsMenuList from 'components/ui/menu/StyledActionsMenuList';
import Link from 'components/ui/links/Link';
import useAicpCardActionsMenuCurrentMenuMutations from 'components/ui/pages/aicps/actions-menu/hooks/useAicpCardActionsMenuCurrentMenuMutations';
import SubMenuController, {
    SUB_MENUS,
} from 'components/ui/pages/aicps/actions-menu/SubMenuController';
import ActiveAicpMenuItems from 'components/ui/pages/aicps/actions-menu/ActiveAicpMenuItems';
import Aicp from 'core/Aicp';
import {Logger} from 'utils/logger';
import {logErrorMessages} from 'utils/graphqlResponse';
import {CompanySourceStatus, CsvCompanySource} from 'core/Aicp/types';
import UploadedFile from 'core/UploadedFile';
import {checkIfFilteredByOutdatedIndustries} from 'components/util/accountFilterUtils';

const logger = new Logger('AicpCardActionsMenuCurrentMenu');

const MAX_NUMBER_OF_COMPARISON_LISTS = 3;

type AicpCardActionsMenuCurrentMenuProps = {
    aicp: Aicp;
    currentStatus: string;
    accountSuppressionLists: CsvCompanySource[];
    comparisonLists: CsvCompanySource[];
    isParentPopperOpen: boolean;
    popperHandleListKeyDown: () => void;
    currentSubMenu: string;
    setCurrentSubMenu: (subMenu: string) => void;
};

const LoadingMenuItem = ({isLoading}: {isLoading: boolean}) => {
    return isLoading ? (
        <StyledLinearProgress width="100%" position="absolute" top={0} />
    ) : null;
};

const AicpCardActionsMenuCurrentMenu = ({
    aicp,
    currentStatus,
    accountSuppressionLists,
    comparisonLists,
    currentSubMenu,
    setCurrentSubMenu,
    isParentPopperOpen,
    popperHandleListKeyDown,
}: AicpCardActionsMenuCurrentMenuProps) => {
    const {
        loading,
        addSuppressionFile,
        removeAccountSuppressionListFromIcp,
        addComparisonList,
        removeComparisonListFromIcp,
    } = useAicpCardActionsMenuCurrentMenuMutations(aicp.id, setCurrentSubMenu);

    const isSuppressionDisabled = accountSuppressionLists.length > 0 || loading;
    const validComparisonLists = comparisonLists.filter(
        (comparisonList: CsvCompanySource) =>
            [
                CompanySourceStatus.PROCESSING,
                CompanySourceStatus.ACTIVE,
            ].includes(comparisonList.status)
    );

    const isAddComparisonDisabled =
        validComparisonLists.length >= MAX_NUMBER_OF_COMPARISON_LISTS ||
        loading;

    const isFilteredByOutdatedIndustries = checkIfFilteredByOutdatedIndustries(
        aicp.accountFilter?.industries
    );

    const addSuppressionListToAicp = (fileData: UploadedFile) => {
        addSuppressionFile({
            variables: {
                icpId: aicp.id,
                accountSuppressionFile: {
                    name: fileData.name,
                    temporaryUrl: fileData.url,
                },
            },
        }).catch((error) => {
            logErrorMessages(error, logger);
        });
    };

    const addComparisonListToAicp = (
        fileData: UploadedFile,
        fileName: string
    ) => {
        addComparisonList({
            variables: {
                icpId: aicp.id,
                fileName: fileName,
                comparisonFile: {
                    name: fileData.name,
                    temporaryUrl: fileData.url,
                },
            },
        }).catch((error) => {
            logErrorMessages(error, logger);
        });
    };

    if (currentSubMenu) {
        return (
            <SubMenuController
                aicp={aicp}
                currentSubMenu={currentSubMenu}
                setCurrentSubMenu={setCurrentSubMenu}
                isSuppressionDisabled={isSuppressionDisabled}
                isAddComparisonDisabled={isAddComparisonDisabled}
                addSuppressionListToAicp={addSuppressionListToAicp}
                addComparisonListToAicp={addComparisonListToAicp}
            />
        );
    }

    return (
        <StyledActionsMenuList
            autoFocusItem={isParentPopperOpen}
            onKeyDown={popperHandleListKeyDown}
            data-testid="aicp-card-default-actions-menu"
        >
            <LoadingMenuItem isLoading={loading} />
            <MenuItem disabled>
                <Typography component="span" className="menu-subheader">
                    Manage
                </Typography>
            </MenuItem>
            <ActiveAicpMenuItems
                {...{
                    isFilteredByOutdatedIndustries,
                    currentStatus,
                    setCurrentSubMenu,
                    isSuppressionDisabled,
                    isAddComparisonDisabled,
                    isDynamic: aicp.isDynamic,
                    aicpId: aicp.id,
                }}
            />

            <MenuItem
                data-testid="btn-delete"
                onClick={() => {
                    setCurrentSubMenu(SUB_MENUS.deleteAicpMenu);
                }}
            >
                <Typography component="span" className="menu-item">
                    Delete
                </Typography>
            </MenuItem>

            <SuppressionContent
                accountSuppressionLists={accountSuppressionLists}
                removeAccountSuppressionListFromIcp={
                    removeAccountSuppressionListFromIcp
                }
            />

            <ComparisonListContent
                comparisonLists={validComparisonLists}
                removeComparisonListFromIcp={removeComparisonListFromIcp}
            />

            {currentStatus === AICP_STATUS.active && (
                // next line must not be a document fragment (mui MenuList)
                <div>
                    <hr />
                    <MenuItem disabled>
                        <Typography component="span" className="menu-subheader">
                            Download
                        </Typography>
                    </MenuItem>

                    <MenuItem data-testid="btn-download-export">
                        <Link
                            variant="body1"
                            href={`/aicp/${aicp.id}/companies/lookalike-companies`}
                        >
                            <Typography component="span" className="menu-item">
                                Download/Export Companies
                            </Typography>
                        </Link>
                    </MenuItem>
                </div>
            )}
        </StyledActionsMenuList>
    );
};

export default AicpCardActionsMenuCurrentMenu;
