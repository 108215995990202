import {isEmpty} from 'lodash';
import {MenuItem, Typography} from 'components/mui';
import {ComparisonListContentPropTypes} from 'components/ui/pages/aicps/actions-menu/prop-types/propTypes';
import DisplayFileItem from 'components/ui/pages/aicps/actions-menu/DisplayFileItem';
import {CompanySourceStatus} from 'core/Aicp/types';

const ComparisonListContent = ({
    comparisonLists,
    removeComparisonListFromIcp,
}) => {
    if (isEmpty(comparisonLists)) {
        return null;
    }

    return (
        <div>
            <hr />
            <MenuItem disabled>
                <Typography
                    component="span"
                    className="menu-subheader"
                    data-testid="header-comparison-lists"
                >
                    Comparison Lists
                </Typography>
            </MenuItem>

            {comparisonLists?.map((comparisonList, index) => (
                <DisplayFileItem
                    key={comparisonList.id || `comparison-${index}`}
                    fileName={
                        comparisonList.name || comparisonList.uploadedFile.name
                    }
                    headerName={`Comparison List ${index + 1}`}
                    onDelete={() => {
                        if (removeComparisonListFromIcp)
                            removeComparisonListFromIcp(comparisonList.id);
                    }}
                    processing={
                        comparisonList.status === CompanySourceStatus.PROCESSING
                    }
                />
            ))}
        </div>
    );
};

ComparisonListContent.propTypes = ComparisonListContentPropTypes;

export default ComparisonListContent;
