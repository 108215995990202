import SearchIcon from '@mui/icons-material/Search';
import {
    Box,
    FormControl,
    IconButton,
    InputAdornment,
    OutlinedInput,
} from 'components/mui';
import {isEqual} from 'lodash';
import debounce from 'lodash/debounce';
import {
    ChangeEventHandler,
    FormEventHandler,
    KeyboardEventHandler,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import styled from 'styled-components';
import usePrevious from 'utils/hooks/usePrevious';

export const SUBMIT_ON_TYPE_DELAY_DEFAULT = 1000; // milliseconds

const StyledFormControl = styled(FormControl)`
    .MuiOutlinedInput-root {
        background-color: ${({theme}) => theme.palette.primary.light};
    }
`;

export enum SubmitEventType {
    TYPE = 'type',
    SUBMIT = 'submit',
}

export interface SearchFieldFormProps {
    id: string;
    customClearIcon?: React.ReactNode;
    onSearchSubmit: (searchQuery: string) => void;
    textfieldPlaceholder?: string;
    textfieldInitialValue?: string;
    submitButtonLabel?: string;
    submitEventType?: SubmitEventType;
    submitOnTypeDelay?: number;
    onChange?: (value: string) => void;
    customClearIconOnClick?: () => void;
}

const SearchFieldForm = ({
    id,
    customClearIcon,
    onSearchSubmit,
    textfieldPlaceholder,
    textfieldInitialValue = '',
    submitButtonLabel = 'Submit the input text.',
    submitEventType = SubmitEventType.SUBMIT,
    submitOnTypeDelay = SUBMIT_ON_TYPE_DELAY_DEFAULT,
    onChange,
    customClearIconOnClick,
}: SearchFieldFormProps) => {
    const [inputValue, setInputValue] = useState<string>(textfieldInitialValue);
    const previousInputValue = usePrevious(inputValue) || '';
    const hasInputValueChanged = !isEqual(inputValue, previousInputValue);

    const onSubmitHandler: FormEventHandler<HTMLFormElement> = useCallback(
        (event) => {
            event.preventDefault();

            if (onSearchSubmit) {
                onSearchSubmit(inputValue);
            }
        },
        [inputValue, onSearchSubmit]
    );

    useEffect(() => {
        if (textfieldInitialValue) {
            setInputValue(textfieldInitialValue);
        }
    }, [textfieldInitialValue]);

    const debouncedOnSearchSubmit = useMemo(() => {
        return debounce(onSearchSubmit, submitOnTypeDelay);
    }, [onSearchSubmit, submitOnTypeDelay]);

    useEffect(() => {
        return () => {
            debouncedOnSearchSubmit.cancel();
        };
    }, []);

    const onChangeHandler: ChangeEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            event.preventDefault();

            const newValue = event.target.value;
            setInputValue(newValue);

            if (onChange) onChange(newValue);

            if (submitEventType === SubmitEventType.TYPE && onSearchSubmit) {
                debouncedOnSearchSubmit(newValue);
            }
        },
        [
            setInputValue,
            submitEventType,
            onSearchSubmit,
            debouncedOnSearchSubmit,
        ]
    );

    const onKeydownHandler: KeyboardEventHandler<HTMLInputElement> =
        useCallback(
            (event) => {
                if (
                    onSearchSubmit &&
                    (event.key === 'Escape' ||
                        (event.key === 'Enter' && inputValue === ''))
                ) {
                    onSearchSubmit('');
                }
            },
            [onSearchSubmit, inputValue]
        );

    return (
        <Box component="form" width="100%" onSubmit={onSubmitHandler}>
            <StyledFormControl
                fullWidth
                size="small"
                aria-label="seach input field"
                variant="outlined"
            >
                <OutlinedInput
                    id={`search-field-form-${id}`}
                    data-testid={`search-field-form-${id}`}
                    autoComplete="off"
                    type={customClearIcon ? 'text' : 'search'}
                    fullWidth
                    value={inputValue}
                    onChange={onChangeHandler}
                    onKeyDown={onKeydownHandler}
                    placeholder={textfieldPlaceholder}
                    inputProps={{
                        'data-hj-allow': true,
                    }}
                    startAdornment={
                        submitEventType === SubmitEventType.TYPE && (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }
                    endAdornment={
                        submitEventType === SubmitEventType.SUBMIT && (
                            <InputAdornment position="end">
                                {customClearIcon && inputValue && (
                                    <IconButton
                                        onClick={
                                            customClearIconOnClick
                                                ? customClearIconOnClick
                                                : () => {
                                                      setInputValue('');
                                                  }
                                        }
                                    >
                                        {customClearIcon}
                                    </IconButton>
                                )}
                                <IconButton
                                    aria-label={submitButtonLabel}
                                    disabled={!hasInputValueChanged}
                                    edge="end"
                                    type="submit"
                                >
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }
                />
            </StyledFormControl>
        </Box>
    );
};

export default SearchFieldForm;
