import CloseIcon from '@mui/icons-material/Close';
import {Box, Button, IconButton, Stack, Typography} from 'components/mui';
import {MINIMUM_SUPPRESSION_LIST_CSV_ROWS} from 'core/Aicp/constants';
import {FileUpload} from 'components/ui/forms/file-upload/FileUpload';
import {FormStateProvider} from 'components/providers/FormStateProvider';
import {FormValidationProvider} from 'components/providers/FormValidationProvider';
import {suppressionFormFields} from 'components/ui/pages/aicps/actions-menu/form-fields/formFields';
import {AddSuppressionMenuPropTypes} from 'components/ui/pages/aicps/actions-menu/prop-types/propTypes';
import {Notification} from 'core/Notification/index.ts';
import styled from 'styled-components';

const MAXIMUM_ROWS_FOR_SUPPRESSION_FILE = 500000;

const AddSuppressionMenuContainer = styled(Box)`
    width: 450px;
    .container-select {
        margin-bottom: 5px;
    }
    h5 {
        font-size: 1.6rem;
        font-weight: ${({theme}) => theme.typography.fontWeightBold};
    }
    h6 {
        font-size: 1.5rem;
        font-weight: ${({theme}) => theme.typography.fontWeightBold};
    }
`;

const AddSuppressionMenuContent = styled(Box)`
    padding: 10px 30px 32px 30px;
    display: flex;
    flex-direction: column;
    gap: ${({theme}) => theme.spacing(2)};
`;

const AddSuppressionMenu = ({
    aicpName,
    aicpId,
    setCurrentSubMenu,
    addSuppressionListToAicp,
    isSuppressionDisabled,
}) => {
    const cancelHandler = () => {
        setCurrentSubMenu(null);
    };

    return (
        <FormStateProvider initialFields={suppressionFormFields}>
            {({
                formState,
                setFieldData,
                initialFields,
                updateMultiSelectValue,
                isMultiSelectOptionSelected,
                addField,
            }) => {
                const {suppressionListFile} = formState;
                return (
                    <FormValidationProvider formState={formState}>
                        {({isFormValid, getFieldInvalidReasons}) => {
                            return (
                                <AddSuppressionMenuContainer data-testid="add-suppression-menu-action">
                                    <Box
                                        p={2.5}
                                        pr={3}
                                        pb={1.25}
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <Box display="flex" flexGrow={1}>
                                            <Typography variant="h5">
                                                Add New Suppression List
                                            </Typography>
                                        </Box>

                                        <IconButton
                                            aria-label="close"
                                            size="small"
                                            color="secondary"
                                            sx={{
                                                position: 'absolute',
                                                right: 8,
                                                top: 8,
                                            }}
                                            onClick={cancelHandler}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                    <hr />
                                    <AddSuppressionMenuContent>
                                        <Box width="100%">
                                            <form data-testid="form-suppressionList">
                                                <FileUpload
                                                    file={
                                                        suppressionListFile.value
                                                    }
                                                    minNumberOfDataRows={
                                                        MINIMUM_SUPPRESSION_LIST_CSV_ROWS
                                                    }
                                                    maxNumberOfDataRows={
                                                        MAXIMUM_ROWS_FOR_SUPPRESSION_FILE
                                                    }
                                                    onComplete={(value) => {
                                                        setFieldData({
                                                            fieldName:
                                                                'suppressionListFile',
                                                            data: {
                                                                value,
                                                            },
                                                        });
                                                    }}
                                                    onRemove={() => {
                                                        setFieldData({
                                                            fieldName:
                                                                'suppressionListFile',
                                                            data: {
                                                                value: null,
                                                            },
                                                        });
                                                    }}
                                                    onError={(e) => {
                                                        Notification.error(e);
                                                    }}
                                                    requiredHeaders={[
                                                        'website',
                                                    ]}
                                                    uploadDirective="suppressionListFile"
                                                    dragAndDropFileUploadId={
                                                        'suppression-list-file-upload'
                                                    }
                                                    convertWebsiteColumnTo={
                                                        'website'
                                                    }
                                                    titleText="Upload a suppression list"
                                                    sampleFileHref="/sample-files/sample-company-list.csv"
                                                    sampleFileName="sample-suppression-list.csv"
                                                />
                                            </form>
                                        </Box>

                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            justifyContent="flex-end"
                                        >
                                            <Button
                                                data-testid="button-cancel-addSuppressionFile"
                                                variant="outlined"
                                                onClick={cancelHandler}
                                            >
                                                CANCEL
                                            </Button>

                                            <Button
                                                data-testid="submit-suppression-list-button"
                                                type="button"
                                                disabled={
                                                    !suppressionListFile?.value ||
                                                    isSuppressionDisabled
                                                }
                                                onClick={() => {
                                                    addSuppressionListToAicp(
                                                        suppressionListFile.value
                                                    );
                                                }}
                                            >
                                                Add Suppression
                                            </Button>
                                        </Stack>
                                    </AddSuppressionMenuContent>
                                </AddSuppressionMenuContainer>
                            );
                        }}
                    </FormValidationProvider>
                );
            }}
        </FormStateProvider>
    );
};
AddSuppressionMenu.propTypes = AddSuppressionMenuPropTypes;

export default AddSuppressionMenu;
