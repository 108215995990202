export function countAlphabetCharactersInString(str: string) {
    str = str.toString();
    const splitString = str.split('');
    let count = 0;

    splitString.forEach((char: string) => {
        if (/[a-zA-Z]/.test(char)) {
            count++;
        }
    });

    return count;
}

export function cleanUnsafeString(unsafeString: string) {
    return unsafeString.replace(/[^a-z0-9]/gi, '_').toLowerCase();
}

export const isStringEmpty = (str: string) => {
    return !str || str?.trim()?.length === 0;
};

export const alphabeticSortPredicate =
    <T>(property: string) =>
    (a: T, b: T) => {
        const aProp: string = a[property];
        const bProp: string = b[property];
        return aProp.localeCompare(bProp);
    };

export const titleCase = (text: string): string => {
    const lowerCaseWords = new Set([
        'a',
        'an',
        'the',
        'and',
        'but',
        'for',
        'nor',
        'or',
        'so',
        'yet',
        'as',
        'at',
        'by',
        'for',
        'in',
        'of',
        'on',
        'to',
        'up',
        'with',
    ]);

    const words = text.split(' ');

    const titleCasedWords = words.map((word, index) => {
        if (index > 0 && lowerCaseWords.has(word.toLowerCase())) {
            return word.toLowerCase();
        }

        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    return titleCasedWords.join(' ');
};
