import {Typography} from 'components/mui';
import AicpCardIndustriesPropTypes from 'components/ui/pages/aicps/aicp-card/prop-types/propTypes.js';

export default function AicpCardIndustries({accountFilter}) {
    if (accountFilter.industries.length > 0) {
        return accountFilter.industries.map((industry) => (
            <Typography key={industry.code}>{industry.description}</Typography>
        ));
    }
    return <Typography>Any Industry</Typography>;
}

AicpCardIndustries.propTypes = AicpCardIndustriesPropTypes;
