import {Typography, CircularProgress, Box} from 'components/mui';
import {
    PopperProvider,
    PopperProviderControlProps,
} from 'components/ui/poppers/PopperProvider';

interface ProcessingIconWithPopperProps {
    message: string;
    iconSize?: number | string;
}

const ProcessingIconWithPopper = ({
    message,
    iconSize = 22,
}: ProcessingIconWithPopperProps) => {
    return (
        <PopperProvider
            placement="left"
            paperProps={{elevation: 6}}
            popperContent={() => {
                return (
                    <Box p={1} data-testid="processing-popper-content">
                        <Typography>{message}</Typography>
                    </Box>
                );
            }}
        >
            {({setIsOpen, anchorRef}: PopperProviderControlProps) => (
                <Box
                    p={1}
                    mr={3}
                    ref={anchorRef}
                    onMouseEnter={() => {
                        setIsOpen(true);
                    }}
                    onMouseLeave={() => {
                        setIsOpen(false);
                    }}
                    data-testid="processing-icon"
                >
                    <CircularProgress size={iconSize} />
                </Box>
            )}
        </PopperProvider>
    );
};

export default ProcessingIconWithPopper;
