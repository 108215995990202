import {mutationFunction} from 'graphql/client';
import {Notification} from 'core/Notification/index';
import useRemoveAccountSuppressionListFromIcp, {
    RemoveAccountSuppressionListFromIcpData,
} from 'components/ui/pages/aicps/actions-menu/hooks/useRemoveAccountSuppressionListFromIcp';
import useRemoveComparisonListFromIcp, {
    RemoveComparisonListFromIcpData,
    RemoveComparisonListFromIcpVariables,
} from 'components/ui/pages/aicps/actions-menu/hooks/useRemoveComparisonListFromIcp';
import {useAicp} from 'components/hooks/useAicp';
import Aicp from 'core/Aicp';
import useAddComparisonList, {
    AddComparisonListMutationData,
    AddComparisonListMutationVariables,
} from 'components/ui/pages/aicps/actions-menu/hooks/useAddComparisonList';
import useAddSuppressionFile, {
    AddSuppressionFileMutationData,
    AddSuppressionFileMutationVariables,
} from 'components/ui/pages/aicps/actions-menu/hooks/useAddSuppressionFile';
import {FetchResult} from '@apollo/client';

export const GENERIC_UPLOAD_FILE_ERROR =
    'An error occurred while trying to upload this file';

export const ACCOUNT_SUPPRESSION_MUTATION_MESSAGES = {
    success: 'Suppression list successfully deleted.',
    error: 'An error occurred while trying to delete the suppression list. Please try again.',
};

export const REMOVE_COMPARISON_LIST_FROM_ICP_MESSAGES = {
    success: 'Comparison list successfully removed.',
    error: 'An error occurred while trying to remove the comparison list. Please try again.',
};

export const onRemoveAccountSuppressionListSuccessCallback = (
    success: boolean
) => {
    if (success) {
        Notification.success(ACCOUNT_SUPPRESSION_MUTATION_MESSAGES.success, {
            removeAfter: 10,
        });
    } else {
        Notification.error(ACCOUNT_SUPPRESSION_MUTATION_MESSAGES.error, {
            removeAfter: 10,
        });
    }
};

export const onRemoveAccountSuppressionListErrorCallback = () => {
    Notification.error(ACCOUNT_SUPPRESSION_MUTATION_MESSAGES.error, {
        removeAfter: 10,
    });
};

export const onRemoveComparisonListSuccessCallback = () => {
    Notification.success(REMOVE_COMPARISON_LIST_FROM_ICP_MESSAGES.success, {
        removeAfter: 10,
    });
};

export const onRemoveComparisonListErrorCallback = () => {
    Notification.error(REMOVE_COMPARISON_LIST_FROM_ICP_MESSAGES.error, {
        removeAfter: 10,
    });
};

type useAicpCardActionsMenuCurrentMenuMutationsResults = {
    loading: boolean;
    currentAicp: Aicp;
    addComparisonList: mutationFunction<
        AddComparisonListMutationData,
        AddComparisonListMutationVariables
    >;
    addSuppressionFile: mutationFunction<
        AddSuppressionFileMutationData,
        AddSuppressionFileMutationVariables
    >;
    removeAccountSuppressionListFromIcp: (
        accountSuppressionListId: string
    ) => Promise<FetchResult<RemoveAccountSuppressionListFromIcpData>>;
    removeComparisonListFromIcp: mutationFunction<
        RemoveComparisonListFromIcpData,
        RemoveComparisonListFromIcpVariables
    >;
};

const useAicpCardActionsMenuCurrentMenuMutations = (
    aicpId: string,
    setCurrentSubMenu: (menuView: string | null) => void
): useAicpCardActionsMenuCurrentMenuMutationsResults => {
    const {aicp: currentAicp, loading: isAicpQueryLoading} = useAicp(aicpId);

    const {
        removeAccountSuppressionListFromIcp,
        loading: isRemoveSuppressionListLoading,
    } = useRemoveAccountSuppressionListFromIcp(
        aicpId,
        onRemoveAccountSuppressionListSuccessCallback,
        onRemoveAccountSuppressionListErrorCallback
    );

    const {
        removeComparisonListFromIcp,
        loading: isRemoveComparisonListLoading,
    } = useRemoveComparisonListFromIcp(
        aicpId,
        onRemoveComparisonListSuccessCallback,
        onRemoveComparisonListErrorCallback
    );

    const {addComparisonList, loading: isComparisonListUploading} =
        useAddComparisonList(aicpId, setCurrentSubMenu);

    const {addSuppressionFile, loading: isSuppressionFileUploading} =
        useAddSuppressionFile(setCurrentSubMenu);

    const isAnythingLoading =
        isRemoveSuppressionListLoading ||
        isRemoveComparisonListLoading ||
        isSuppressionFileUploading ||
        isComparisonListUploading ||
        isAicpQueryLoading;
    return {
        loading: isAnythingLoading,
        currentAicp,
        addSuppressionFile,
        removeAccountSuppressionListFromIcp,
        addComparisonList,
        removeComparisonListFromIcp,
    };
};

export default useAicpCardActionsMenuCurrentMenuMutations;
