import PropTypes from 'prop-types';
import {Box, Chip} from 'components/mui';
export default function AicpCardTags({tags}) {
    return (
        <Box display="flex" flexWrap="wrap">
            {tags.map((tag) => (
                <Box key={tag} mr={1} mt={1}>
                    <Chip key={tag} label={tag} />
                </Box>
            ))}
        </Box>
    );
}

AicpCardTags.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.string),
};
