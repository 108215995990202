import PropTypes from 'prop-types';

export const AicpCardPropTypes = {
    aicp: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        accountFilter: PropTypes.shape({
            headcountRange: PropTypes.shape({
                max: PropTypes.number,
                min: PropTypes.number,
            }),
            industries: PropTypes.arrayOf(
                PropTypes.shape({
                    description: PropTypes.string,
                    type: PropTypes.string,
                    code: PropTypes.string,
                })
            ),
            revenueRange: PropTypes.shape({
                max: PropTypes.number,
                min: PropTypes.number,
            }),
        }),
        bestCustomerSources: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string,
                createdAt: PropTypes.instanceOf(Date),
                uploadedFile: PropTypes.shape({
                    name: PropTypes.string,
                    url: PropTypes.string,
                    id: PropTypes.string,
                    size: PropTypes.string,
                    uploadedAt: PropTypes.instanceOf(Date),
                }),
            })
        ),
        targetSources: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                type: PropTypes.string,
                createdAt: PropTypes.instanceOf(Date),
                name: PropTypes.string,
                uploadedFile: PropTypes.shape({
                    name: PropTypes.string,
                    url: PropTypes.string,
                    size: PropTypes.string,
                    uploadedAt: PropTypes.instanceOf(Date),
                }),
            })
        ),
        updatedAt: PropTypes.instanceOf(Date),
        originalCreationDate: PropTypes.instanceOf(Date),
        createdBy: PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.string,
        }),
        tags: PropTypes.arrayOf(PropTypes.string),
    }),
    clientOrgTags: PropTypes.arrayOf(PropTypes.string),
};

export const AicpCardAccountFiltersPropTypes = {
    headcountRange: PropTypes.shape({
        max: PropTypes.number,
        min: PropTypes.number,
    }),
    headcountBins: PropTypes.arrayOf(
        PropTypes.shape({
            bin: PropTypes.number,
            range: PropTypes.shape({
                max: PropTypes.number,
                min: PropTypes.number,
            }),
        })
    ),
    industries: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string,
            type: PropTypes.string,
            code: PropTypes.string,
        })
    ),
    revenueRange: PropTypes.shape({
        max: PropTypes.number,
        min: PropTypes.number,
    }),
};

export const AicpCardIndustriesPropTypes = {
    accountFilter: PropTypes.shape(AicpCardAccountFiltersPropTypes),
};

export default AicpCardPropTypes;
