import React, {KeyboardEvent, Ref, useRef, useState} from 'react';
import {
    ClickAwayListener,
    Grow,
    Paper,
    PaperProps,
    Popper,
    PopperPlacementType,
} from 'components/mui';
import makeStyles from '@mui/styles/makeStyles';
import {ThemeOptions} from '@mui/material/styles/createTheme';

// timeout time from MUI github repo
export const POPPER_CLOSE_ANIMATION_TIME = 350;

const useStyles = makeStyles((theme: ThemeOptions) => ({
    root: {
        flexGrow: 1,
        alignSelf: 'center',
    },

    popper: {
        zIndex: 1100,
        '&[data-popper-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
            },
        },
        '&[data-popper-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
            },
        },
        '&[data-popper-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
            },
        },
        '&[data-popper-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
            },
        },
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
}));

export type TypingKeyboardEvent = KeyboardEvent<
    HTMLInputElement | HTMLTextAreaElement | HTMLDivElement
>;

export interface PopperProviderControlProps {
    isOpen?: boolean;
    setIsOpen?: (boolean) => void;
    anchorRef?: Ref<HTMLButtonElement>;
    handleToggle?: () => void;
}

export interface PopperContentProps {
    anchorRef?: Ref<HTMLButtonElement>;
    handleClose?: () => void;
    handleListKeyDown?: () => void;
    handleToggle?: () => void;
    setIsOpen?: () => void;
    isOpen?: boolean;
}

export interface PopperProviderProps {
    children: React.FunctionComponent;
    popperContent: React.FunctionComponent;
    placement?: PopperPlacementType;
    enableArrow?: boolean;
    flip?: boolean;
    preventOverflow?: boolean;
    preventOverflowParent?: string;
    onClose?: (e?: Event) => void;
    className?: string;
    paperProps?: PaperProps;
    popperContentProps?: unknown;
}

const PopperProvider = ({
    children,
    className,
    placement = 'left-start',
    enableArrow = false,
    popperContent,
    flip = false,
    preventOverflow = false,
    preventOverflowParent = 'viewport',
    onClose = () => undefined,
    paperProps,
    popperContentProps = {},
}: PopperProviderProps) => {
    const classes = useStyles();
    const anchorRef = useRef(null);
    const [arrowRef, setArrowRef] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    function onAnimationEnd(event?: Event) {
        setTimeout(() => {
            if (event) {
                onClose(event);
            } else {
                onClose();
            }
        }, POPPER_CLOSE_ANIMATION_TIME);
    }

    function handleToggle() {
        setIsOpen(!isOpen);
        if (isOpen) {
            onAnimationEnd();
        }
    }

    function handleClose(event) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        if (anchorRef.current && anchorRef?.current?.contains(event.target)) {
            return;
        }
        setIsOpen(false);
        onAnimationEnd(event);
    }

    function handleListKeyDown(event: KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setIsOpen(false);
        }
    }

    return (
        <div className={classes.root}>
            {children({
                anchorRef,
                handleClose,
                handleListKeyDown,
                handleToggle,
                setIsOpen,
                isOpen,
            })}
            <Popper
                className={classes.popper}
                open={isOpen}
                anchorEl={anchorRef.current}
                placement={placement}
                role={undefined}
                transition
                modifiers={[
                    {
                        name: 'flip',
                        enabled: flip,
                    },
                    {
                        name: 'arrow',
                        enabled: enableArrow,
                        options: {
                            element: arrowRef,
                        },
                    },
                    {
                        name: 'preventOverflow',
                        enabled: preventOverflow,
                        options: {
                            boundary: preventOverflowParent,
                        },
                    },
                    {
                        name: 'hide',
                        enabled: false,
                    },
                ]}
                nonce={undefined}
                onResize={undefined}
                onResizeCapture={undefined}
            >
                {({TransitionProps}) => (
                    <Grow {...TransitionProps}>
                        <Paper className={className} {...paperProps}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <div>
                                    {popperContent({
                                        anchorRef,
                                        handleClose,
                                        handleListKeyDown,
                                        handleToggle,
                                        setIsOpen,
                                        isOpen,
                                        ...(popperContentProps as object),
                                    })}

                                    {enableArrow && (
                                        <span
                                            className={classes.arrow}
                                            ref={setArrowRef}
                                        />
                                    )}
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

export {PopperProvider};
