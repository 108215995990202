import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import {Box, Button, Grid, Typography} from 'components/mui';
import Link from 'components/ui/links/Link';
import {AICP_ANALYSIS_PAGE_EXPLORATION_TAB_ROUTE} from 'components/ui/pages/aicp/AicpAnalysisSubPage';
import {AICP_SETTINGS_PAGE_USER_ASSIGNMENT_TAB_ROUTE} from 'components/ui/pages/aicp/edit-settings/AicpSettingsSubPage';
import AicpCardActionsMenu from 'components/ui/pages/aicps/actions-menu/AicpCardActionsMenu';
import EditAicpName from 'components/ui/pages/aicps/aicp-card/EditAicpName';
import {
    AICP_STATUS,
    AICP_STATUS_ICON,
} from 'components/ui/pages/aicps/statuses.js';
import useAicpStatusPollingQuery from 'components/ui/pages/aicps/useAicpStatusPollingQuery';
import {
    PopperContentProps,
    PopperProvider,
    PopperProviderControlProps,
} from 'components/ui/poppers/PopperProvider';
import {AICP_URL} from 'components/util/routeConstants';
import Aicp from 'core/Aicp';
import Image from 'next/legacy/image';
import Router from 'next/router';
import styled from 'styled-components';
import {logger} from 'utils/logger';
import ProcessingIconWithPopper from 'components/ui/pages/aicps/aicp-card/ProcessingIconWithPopper';

const ActionsWrapper = styled.div`
    display: flex;
    align-items: end;
    gap: ${({theme}) => theme.spacing(1)};
    @media (max-width: ${(props) => props.theme.mobileToDesktopBreakpoint}px) {
        margin: 20px 0;
    }
`;

const FailedIcon = styled.div`
    margin: 0 10px;
    display: flex;
`;

const CardHeader = styled.div`
    margin-bottom: ${({theme}) => theme.spacing(2)};
    .AicpCardHeaderContent__highlight-text {
        font-size: 1.4rem;
        color: #19a884;
        margin-left: 4px;
        display: inline-block;
    }
    .AicpCardHeaderContent__title {
        font-weight: ${({theme}) => theme.typography.fontWeightBold};
        font-size: 2rem;
        color: ${({theme}) => theme.palette.secondary.dark};
        margin-bottom: 0;
    }
`;

const GENERATING_AICP_MESSAGE = 'Currently generating prospects for this aiCP.';

const FailedIconWithPopper = () => {
    return (
        <PopperProvider
            placement="left"
            paperProps={{elevation: 6}}
            popperContent={() => {
                return (
                    <Box p={1} data-testid="failed-popper-content">
                        <Typography>
                            Failed to generate prospects for the aiCP.
                        </Typography>
                    </Box>
                );
            }}
        >
            {({setIsOpen, anchorRef}: PopperProviderControlProps) => (
                <Box
                    ref={anchorRef}
                    onMouseEnter={() => {
                        setIsOpen(true);
                    }}
                    onMouseLeave={() => {
                        setIsOpen(false);
                    }}
                    data-testid="failed-icon"
                    display="flex"
                    alignSelf="self-end"
                >
                    <FailedIcon>
                        <Image
                            src={AICP_STATUS_ICON.failed}
                            width={30}
                            height={30}
                        />
                    </FailedIcon>
                </Box>
            )}
        </PopperProvider>
    );
};

const StatusIcon = ({aicp}: AicpHeaderIconsProps) => {
    const isFailed = aicp?.status === AICP_STATUS.failed;
    const isProcessing = aicp?.status === AICP_STATUS.processing;
    const isActive = aicp?.status === AICP_STATUS.active;
    const isUpdating = aicp?.status === AICP_STATUS.updating;

    if (isFailed) {
        return <FailedIconWithPopper />;
    }
    if (isProcessing || isUpdating) {
        return <ProcessingIconWithPopper message={GENERATING_AICP_MESSAGE} />;
    }
    if (isActive) {
        return (
            <Button
                type="button"
                variant="outlined"
                color="secondary"
                data-testid="active-button"
                disableElevation
                onClick={() => {
                    Router.push(
                        `${AICP_URL}/${aicp.id}/${AICP_ANALYSIS_PAGE_EXPLORATION_TAB_ROUTE}`
                    ).catch((e) => {
                        logger.error(e);
                    });
                }}
            >
                Analysis
            </Button>
        );
    }

    return <ProcessingIconWithPopper message={GENERATING_AICP_MESSAGE} />;
};

interface AicpHeaderIconsProps {
    aicp: Aicp;
}

const AiCPSettingsIcon = ({aicp}: AicpHeaderIconsProps) => {
    if (aicp.status === AICP_STATUS.active) {
        return (
            <Box alignSelf="center">
                <Link
                    href={`${AICP_URL}/${aicp.id}/${AICP_SETTINGS_PAGE_USER_ASSIGNMENT_TAB_ROUTE}`}
                >
                    <SettingsIcon />
                </Link>
            </Box>
        );
    }
};

interface AicpCardHeaderProps {
    aicp: Aicp;
    refetchAicps: () => Promise<unknown>;
}

export default function AicpCardHeader({
    aicp,
    refetchAicps,
}: AicpCardHeaderProps) {
    const {id, name, status: initialStatus} = aicp;
    const status = useAicpStatusPollingQuery({
        id,
        name,
        initialStatus,
        refetchAicps,
    });

    return (
        <CardHeader
            className="AicpCard__header"
            aria-controls={`${id}-content`}
            id={`${id}-header`}
        >
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Typography
                                variant="h2"
                                className="AicpCardHeaderContent__title"
                            >
                                {name}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <PopperProvider
                                flip
                                preventOverflow
                                placement="right"
                                enableArrow={false}
                                popperContent={({
                                    handleClose,
                                    setIsOpen,
                                }: PopperContentProps) => {
                                    return (
                                        <EditAicpName
                                            aicpName={name}
                                            handleClose={handleClose}
                                            aicpId={id}
                                            setIsOpen={setIsOpen}
                                        />
                                    );
                                }}
                            >
                                {({
                                    handleToggle,
                                    anchorRef,
                                }: PopperProviderControlProps) => {
                                    return (
                                        <Box
                                            ref={anchorRef}
                                            aria-haspopup="true"
                                        >
                                            <EditIcon
                                                data-testid="edit-icon-aicp-name"
                                                onClick={handleToggle}
                                                cursor="pointer"
                                                fontSize="small"
                                                color="secondary"
                                            />
                                        </Box>
                                    );
                                }}
                            </PopperProvider>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <ActionsWrapper>
                        <StatusIcon aicp={{...aicp, status}} />
                        <AicpCardActionsMenu
                            aicp={aicp}
                            currentStatus={status}
                        />
                        <AiCPSettingsIcon aicp={aicp} />
                    </ActionsWrapper>
                </Grid>
            </Grid>
        </CardHeader>
    );
}
