import {useCallback, useState} from 'react';
import {Box, Button, Stack, Typography} from 'components/mui';
import {PrivatePage} from 'components/ui/page/PrivatePage';
import AicpCards from 'components/ui/pages/aicps/aicp-card/AicpCards';
import {PAGE_ID_AICPS} from 'components/util/constants';
import {CREATE_ICP_URL} from 'components/util/routeConstants';
import useAicpsQuery from 'components/hooks/useAicpsQuery';
import useStateMachine from 'components/hooks/useStateMachine';
import {SubPage} from 'components/ui/page/SubPage';
import AicpSplash from 'components/ui/pages/aicps/AicpSplash';
import {PageLoadingSpinner} from 'components/ui/graphics/PageLoadingSpinner';
import SearchFieldForm, {
    SubmitEventType,
} from 'components/ui/forms/SearchFieldForm';
import useAicpTextFilter from 'components/ui/pages/aicps/useAicpsTextFilter';
import {Logger} from 'utils/logger';
import Link from 'components/ui/links/Link';

const logger = new Logger('pages/aicps');

const state = {
    LOADING_AICPS: 'loadingAicps',
    DISPLAYING_AICPS: 'displayingAicps',
    DISPLAYING_ERROR: 'error',
};
const event = {
    AICPS_RECEIVED: 'aicpsReceived',
    ERROR: 'error',
    RELOAD: 'reload',
};
const aicpsStateMachineSpec = {
    initialState: state.LOADING_AICPS,
    states: {
        [state.LOADING_AICPS]: {
            [event.AICPS_RECEIVED]: {
                newState: state.DISPLAYING_AICPS,
            },
            [event.ERROR]: {
                newState: state.DISPLAYING_ERROR,
                action(error) {
                    logger.error('Error while loading aICPs', error);
                },
            },
        },
        [state.DISPLAYING_ERROR]: {
            [event.RELOAD]: {
                newState: state.LOADING_AICPS,
                action(refetchAicps: () => void) {
                    refetchAicps();
                },
            },
        },
    },
};

interface AicpsPageContentProps {
    searchTerm: string;
}

const AicpsPageContent = ({searchTerm}: AicpsPageContentProps) => {
    const [pageState, dispatchEvent] = useStateMachine(aicpsStateMachineSpec);
    const {filterAicps} = useAicpTextFilter(searchTerm);
    const {aicps, refetchAicps} = useAicpsQuery({
        filter: filterAicps,
        onCompleted() {
            dispatchEvent(event.AICPS_RECEIVED);
        },
        onError(error) {
            dispatchEvent(event.ERROR, error);
        },
        notifyOnNetworkStatusChange: true,
    });

    switch (pageState) {
        case state.LOADING_AICPS:
            return <PageLoadingSpinner />;
        case state.DISPLAYING_AICPS:
            if (aicps?.length <= 0) {
                if (searchTerm) {
                    return <NoMatchingAicps searchTerm={searchTerm} />;
                }
                return <AicpSplash />;
            }
            return <AicpCards aicps={aicps} refetchAicps={refetchAicps} />;
        case state.DISPLAYING_ERROR:
            return (
                <ErrorLoadingAicps
                    tryLoadingAgain={() => {
                        dispatchEvent(event.RELOAD, refetchAicps);
                    }}
                />
            );
        default:
            return <AicpSplash />;
    }
};

const NoMatchingAicps = ({searchTerm}: AicpsPageContentProps) => {
    return (
        <Box p={12} display="flex" justifyContent="center" alignItems="center">
            <Typography>
                No aiCP found with a name or tags that match &ldquo;
                {searchTerm}&rdquo;.
            </Typography>
        </Box>
    );
};

interface ErrorLoadingAicpsProps {
    tryLoadingAgain: () => void;
}

const ErrorLoadingAicps = ({tryLoadingAgain}: ErrorLoadingAicpsProps) => {
    return (
        <>
            <Box
                p={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Typography>
                    There was an error while trying to load your aICPs
                </Typography>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
                <Button onClick={tryLoadingAgain}>Try again</Button>
            </Box>
        </>
    );
};

const AicpsPage = () => {
    const [searchTerm, setSearchTerm] = useState('');

    const onSearchSubmit = useCallback(
        (text) => {
            setSearchTerm(text);
        },
        [setSearchTerm]
    );

    return (
        <PrivatePage pageId={PAGE_ID_AICPS}>
            <SubPage
                title="My aiCPs"
                rightColumns={{xs: 12, md: 6}}
                headerContent={
                    <Stack
                        direction="row"
                        spacing={2}
                        width="100%"
                        alignItems="center"
                    >
                        <SearchFieldForm
                            id="aicps"
                            onSearchSubmit={onSearchSubmit}
                            textfieldPlaceholder="Search aiCPs by name or tag."
                            submitEventType={SubmitEventType.TYPE}
                        />

                        <Link href={CREATE_ICP_URL}>
                            <Button
                                data-testid="submit-icp-button"
                                type="button"
                                size="medium"
                            >
                                New aiCP
                            </Button>
                        </Link>
                    </Stack>
                }
            >
                <AicpsPageContent searchTerm={searchTerm} />
            </SubPage>
        </PrivatePage>
    );
};

export default AicpsPage;
