import AICP from 'graphql/pages/aicp/aicp.graphql';
import {useQuery} from 'graphql/client';
import {Logger} from 'utils/logger';
import {logErrorMessages} from 'utils/graphqlResponse';

const logger = new Logger('useAicp');

function useAicp(aicpID) {
    const {data, loading, error} = useQuery(AICP, {
        variables: {idealCustomerProfileId: aicpID},
    });
    const aicp = data?.idealCustomerProfile?.idealCustomerProfile;

    if (error) {
        logErrorMessages(error, logger);
    }
    return {
        aicp,
        loading,
    };
}

export {useAicp};
