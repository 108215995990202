import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {
    Box,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from 'components/mui';
import {parse, ParseResult} from 'papaparse';

const StyledTable = styled(Table)`
    .MuiTableCell-root {
        border: 1px solid ${({theme}) => theme.palette.primary.disabled};
    }
`;

const SampleFileTable = ({sampleFileUrl}: {sampleFileUrl: string}) => {
    const [csvData, setCsvData] = useState<string[][]>(null);

    useEffect(() => {
        if (sampleFileUrl) {
            parse(sampleFileUrl, {
                download: true,
                complete: (results: ParseResult<string[]>) => {
                    setCsvData(results?.data);
                },
            });
        }
    }, []);

    if (csvData && csvData.length > 0) {
        return (
            <Box pt={1} display="table">
                <StyledTable>
                    <TableHead>
                        <TableRow>
                            {csvData[0].map((header) => {
                                return (
                                    <TableCell key={header}>{header}</TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {csvData.slice(1).map((rowData, rowIndex) => {
                            return (
                                <TableRow key={`row-${rowIndex}-${rowData[0]}`}>
                                    {rowData.map((cellData, cellIndex) => {
                                        return (
                                            <TableCell
                                                key={`${csvData[0][cellIndex]}-row-${rowIndex}`}
                                            >
                                                {cellData}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </StyledTable>
            </Box>
        );
    }
};

export default SampleFileTable;
