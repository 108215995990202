import {useQuery} from 'graphql/client';
import {AICP_STATUS} from 'components/ui/pages/aicps/statuses.js';
import AICP_STATUS_QUERY from 'graphql/pages/aicp/aicpStatus.graphql';
import AICPS from 'graphql/pages/aicps/Aicps.graphql';
import {Notification} from 'core/Notification/index.ts';
import {Logger} from 'utils/logger';

const logger = new Logger('useAicpStatusPollingQuery');

export const AICP_STATUS_POLL_INTERVAL = 30000;

const getUpdatedAicpStatus = ({data, loading, error}) => {
    const {idealCustomerProfile} = data?.idealCustomerProfile || {
        status: AICP_STATUS.processing,
    };

    if (loading) {
        return AICP_STATUS.processing;
    }

    if (error) {
        return AICP_STATUS.failed;
    }

    return idealCustomerProfile.status;
};

export default function useAicpStatusPollingQuery({
    id,
    name,
    initialStatus,
    refetchAicps,
}) {
    if (
        initialStatus !== AICP_STATUS.processing &&
        initialStatus !== AICP_STATUS.updating
    ) {
        return initialStatus;
    }

    const {data, loading, error, stopPolling} = useQuery(AICP_STATUS_QUERY, {
        pollInterval: AICP_STATUS_POLL_INTERVAL,
        variables: {idealCustomerProfileId: id},
        notifyOnNetworkStatusChange: true,
        refetchQueries: [{query: AICPS}],
    });

    const status = getUpdatedAicpStatus({data, loading, error});

    if (status === AICP_STATUS.archived) {
        refetchAicps();
        stopPolling();
    }

    if (status === AICP_STATUS.active) {
        Notification.success(`The aiCP ${name} is now active`);
        stopPolling();
    }

    if (status === AICP_STATUS.failed) {
        stopPolling();
        logger.error(`Failed to create ${name}.`);
    }

    return status;
}
