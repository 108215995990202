import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import {PropsWithChildren} from 'react';
import {useTheme} from '@mui/material/styles';
import {
    Breakpoint,
    Box,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Typography,
} from 'components/mui';
import {ExternalLink} from 'components/ui/links/Link';
import StyledLinearProgress from 'components/ui/graphics/StyledLinearProgress';
import {DragAndDropArea} from 'components/ui/forms/file-upload/DragAndDropArea';
import {DragAndDropFileUpload} from 'components/ui/forms/file-upload/DragAndDropFileUpload';
import SampleFileTable from 'components/ui/tables/SampleFileTable';
import {
    UploadedFileData,
    FileUploadProvider,
} from 'components/providers/FileUploadProvider';
import {FieldData} from 'components/providers/FormStateProvider';

const StyledTypography = styled(Typography)`
    strong {
        font-weight: 600;
    }
`;

export interface FileUploadDialogProps {
    isOpen: boolean;
    closeDialog: () => void;
    titleText?: string;
    isCloseButtonDisplayed?: boolean;
    maxWidth?: Breakpoint | false;
    maxNumberOfDataRows: number;
    minNumberOfDataRows?: number;
    requiredHeaders: string[];
    additionalInstructionText?: string;
    sampleFileHref?: string;
    sampleFileName?: string;
    acceptedFileFormats?: string[];
    uploadedFile: UploadedFileData;
    onUploadComplete: (fileData: FieldData['value']) => void;
    onFileRemove: () => void;
    onUploadError: (errorMessage: string) => void;
    uploadDirective: string;
    border: string;
    dragAndDropFileUploadId?: string;
    convertWebsiteColumnTo?: string;
}

interface FileRequirementsProps {
    maxNumberOfDataRows: number;
    minNumberOfDataRows?: number;
    requiredHeaders: string[];
    additionalInstructionText?: string;
    acceptedFileFormats: string[];
}

interface FileUploadProviderChildrenProps {
    dragAndDropFileUploadId?: string;
    onFileUpload: () => void;
    errorMessage?: string;
    isFileUploading: boolean;
}

const FileRequirements = ({
    minNumberOfDataRows,
    maxNumberOfDataRows,
    requiredHeaders,
    additionalInstructionText,
    acceptedFileFormats,
}: FileRequirementsProps) => {
    const shouldDisplayMinNumberOfDataRows = Boolean(
        minNumberOfDataRows && minNumberOfDataRows > 0
    );
    const shouldDisplayMaxNumberOfDataRows = Boolean(
        maxNumberOfDataRows && maxNumberOfDataRows > 0
    );
    return (
        <>
            <DialogContentText component="div">
                <StyledTypography variant="subtitle1" fontWeight="700">
                    File Requirements
                </StyledTypography>
                <StyledTypography>
                    File Format:{' '}
                    <strong>{acceptedFileFormats.join(', ')}</strong>
                </StyledTypography>
                {shouldDisplayMinNumberOfDataRows && (
                    <StyledTypography>
                        Minimum number of rows:{' '}
                        <strong>{minNumberOfDataRows}</strong>
                    </StyledTypography>
                )}
                {shouldDisplayMaxNumberOfDataRows && (
                    <StyledTypography>
                        Maximum number of rows:{' '}
                        <strong>{maxNumberOfDataRows}</strong>
                    </StyledTypography>
                )}
                <StyledTypography>
                    Required header(s):{' '}
                    <strong>{requiredHeaders.join(', ')}</strong>
                </StyledTypography>
            </DialogContentText>
            {additionalInstructionText && (
                <DialogContentText pt={2} component="div">
                    <StyledTypography>
                        <strong>{additionalInstructionText}</strong>
                    </StyledTypography>
                </DialogContentText>
            )}
        </>
    );
};

const FileUploadProviderChildren = ({
    dragAndDropFileUploadId,
    onFileUpload,
    errorMessage,
    isFileUploading,
}: FileUploadProviderChildrenProps) => {
    const {
        palette: {secondary, error: errorColor},
    } = useTheme();
    if (isFileUploading) {
        return (
            <DragAndDropArea>
                <Box
                    className="border-area"
                    border={`2px solid ${
                        errorMessage ? errorColor.main : secondary.main
                    }`}
                >
                    <Typography>Upload in Progress...</Typography>
                    <StyledLinearProgress />
                </Box>
            </DragAndDropArea>
        );
    }

    return (
        <>
            <DragAndDropFileUpload
                id={dragAndDropFileUploadId}
                onDropAccepted={onFileUpload}
                border={`2px solid ${
                    errorMessage ? errorColor.main : secondary.main
                }`}
            />
            {errorMessage && (
                <DialogContentText pt={1} variant="body2" color="error">
                    {errorMessage}
                </DialogContentText>
            )}
        </>
    );
};

const FileUploadDialog = ({
    isOpen,
    closeDialog,
    titleText,
    isCloseButtonDisplayed = true,
    maxWidth,
    maxNumberOfDataRows,
    minNumberOfDataRows,
    requiredHeaders,
    additionalInstructionText,
    sampleFileHref,
    sampleFileName,
    acceptedFileFormats = ['csv'],
    uploadedFile,
    onUploadComplete,
    onFileRemove,
    onUploadError,
    uploadDirective,
    border,
    dragAndDropFileUploadId,
    convertWebsiteColumnTo,
}: PropsWithChildren<FileUploadDialogProps>) => {
    return (
        <Dialog
            open={isOpen}
            onClose={closeDialog}
            maxWidth={maxWidth}
            fullWidth
            aria-labelledby="upload-dialog-title"
            aria-describedby="upload-dialog-description"
        >
            {titleText && (
                <DialogTitle id="upload-dialog-title">{titleText}</DialogTitle>
            )}

            {isCloseButtonDisplayed && (
                <IconButton
                    aria-label="close"
                    size="small"
                    color="secondary"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                    onClick={closeDialog}
                >
                    <CloseIcon />
                </IconButton>
            )}
            <DialogContent>
                <FileRequirements
                    minNumberOfDataRows={minNumberOfDataRows}
                    maxNumberOfDataRows={maxNumberOfDataRows}
                    requiredHeaders={requiredHeaders}
                    additionalInstructionText={additionalInstructionText}
                    acceptedFileFormats={acceptedFileFormats}
                />

                {sampleFileHref && (
                    <>
                        <DialogContentText pt={4} component="div">
                            <Typography variant="subtitle1" fontWeight="700">
                                Example
                            </Typography>
                        </DialogContentText>
                        <SampleFileTable sampleFileUrl={sampleFileHref} />
                        <DialogContentText pt={2} color="primary">
                            <ExternalLink
                                href={sampleFileHref}
                                download={sampleFileName || sampleFileHref}
                            >
                                Download a sample CSV
                            </ExternalLink>
                        </DialogContentText>
                    </>
                )}
                <Box pt={4}>
                    <FileUploadProvider
                        file={uploadedFile}
                        minNumberOfDataRows={minNumberOfDataRows}
                        maxNumberOfDataRows={maxNumberOfDataRows}
                        onComplete={onUploadComplete}
                        onRemove={onFileRemove}
                        onError={onUploadError}
                        requiredHeaders={requiredHeaders}
                        uploadDirective={uploadDirective}
                        border={border}
                        dragAndDropFileUploadId={dragAndDropFileUploadId}
                        convertWebsiteColumnTo={convertWebsiteColumnTo}
                    >
                        {FileUploadProviderChildren}
                    </FileUploadProvider>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default FileUploadDialog;
