import {AICP_STATUS} from 'components/ui/pages/aicps/statuses.js';
import {useQuery} from 'graphql/client';
import AICPS from 'graphql/pages/aicps/Aicps.graphql';
import getActiveIcps from 'components/util/getActiveIcps.js';
import {Logger} from 'utils/logger';
import {logErrorMessages} from 'utils/graphqlResponse';

const logger = new Logger('useAicpsQuery');

function getAicpsFromResponseData(data) {
    const response = data?.idealCustomerProfiles;
    if (response?.status?.success && response?.idealCustomerProfiles) {
        return response.idealCustomerProfiles.filter(
            (aicp) =>
                aicp.status !== AICP_STATUS.removed &&
                aicp.status !== AICP_STATUS.archived
        );
    }

    return [];
}

function defaultOnError(error) {
    logErrorMessages(error, logger);
}

export default function useAicpsQuery({
    onError = defaultOnError,
    onCompleted = null,
    filter = (aicps) => aicps,
    notifyOnNetworkStatusChange = null,
}) {
    const {
        error,
        data,
        loading,
        refetch: refetchAicps,
    } = useQuery(AICPS, {
        variables: {},
        onError,
        onCompleted(data) {
            if (onCompleted) {
                onCompleted(filter(getAicpsFromResponseData(data)));
            }
        },
        notifyOnNetworkStatusChange,
    });

    const aicps = getAicpsFromResponseData(data);

    return {
        aicps: filter(aicps),
        getActiveAicps: getActiveIcps(aicps),
        queryProps: {error, data, loading},
        refetchAicps,
    };
}
