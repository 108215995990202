import _ from 'lodash';

export default function useAicpTextFilter(searchTerm) {
    const filterAicps = (aicps) => {
        if (searchTerm?.length > 0) {
            const filteredAicps = aicps.filter((aicp) => {
                const regex = new RegExp(_.escapeRegExp(searchTerm), 'gi');

                const doesAicpNameMatch = aicp.name.match(regex)?.length > 0;

                if (doesAicpNameMatch) return true;

                const doAicpTagsMatch = aicp.tags.some(
                    (tag) => tag.match(regex)?.length > 0
                );

                return doAicpTagsMatch;
            });

            return filteredAicps;
        }

        return aicps;
    };
    return {filterAicps};
}
