import type {DropdownOption} from 'components/types';
import Company from 'core/Company';
import {CsvExportStatus} from 'core/Exports/types';
import {IntRange} from 'core/Filters/index';
import UploadedFile from 'core/UploadedFile';

export enum AicpStatus {
    FAILED = 'FAILED',
    PROCESSING = 'PROCESSING',
    REMOVED = 'REMOVED',
    ACTIVE = 'ACTIVE',
}

export enum CompanySourceStatus {
    STARTED = 'STARTED',
    PROCESSING = 'PROCESSING',
    ACTIVE = 'ACTIVE',
    FAILED = 'FAILED',
    REMOVED = 'REMOVED',
}

export interface CsvCompanySource {
    id: string;
    type: string;
    createdAt: Date;
    name: string;
    uploadedFile: UploadedFile;
    status: CompanySourceStatus;
}

export interface ScoredComparisonList {
    id: string;
    status: CsvExportStatus;
}

export interface ScoredComparisonListCsvExports {
    comparisonList: CsvCompanySource;
    scoredComparisonList: ScoredComparisonList;
}

export enum IndustryType {
    SIC = 'SIC',
    RIC_4 = 'RIC4',
    RIC_5 = 'RIC5',
}

export interface Industry {
    description: string;
    code: string;
    type?: string;
    level?: number;
    parentCode?: string;
}

export interface IcpFormOptions {
    industryOptions: DropdownOption[];
    ric5IndustryOptions: Industry[];
    userOptions: DropdownOption[];
    headcountOptions: DropdownOption[];
    stateOptions: DropdownOption[];
}

export interface IcpAccountIndustry {
    code: string;
    description: string;
}

export interface AccountFilter {
    headcountRange: IntRange;
    revenueRange: IntRange;
    headcountBins: number[];
    industries: Industry[];
    states: string[];
}

export interface ProspectFilter {
    jobFunctions: string[];
    seniorityLevels: string[];
}

export interface LatestSeedListAccountCsvExport {
    id: string;
}

export type CompanyExegraphicLens = {
    name: string;
};

export type AccountCustomExegraphicLens = {
    lensName: string;
    value: string;
};

export type AccountResponse = {
    id: string;
    company: Company;
    customExegraphicLensRecords: AccountCustomExegraphicLens[];
};

export type ComparisonListResponse = {
    id: string;
    name: CsvCompanySource['name'];
};

type previouslyExported = {
    platform: string;
    isExported: boolean;
};

export type AicpScoredAccountResponse = {
    id: string;
    score: number;
    tunedScore: number;
    starScore: number;
    scoreDelta: number;
    account: AccountResponse;
    exportCounts?: AccountIcpScoreExportCounts;
    previouslyExportedToCsv?: boolean;
    previouslyExportedToSalesforce?: boolean;
    previouslyExportedToSalesforceSandbox?: boolean;
    previouslyExportedToHubspot?: boolean;
    previouslyExportedToPlatforms: previouslyExported[];
    isSuppressionMatch: boolean;
    isSeed: boolean;
    targetSourceIds: string[];
    allComparisonLists?: ComparisonListResponse[];
    droppedReason: string;
};

export type Prospect = {
    id: string;
    recentEvents?: string[];
    sampleContact?: SampleContact;
    enrichedContact?: EnrichedContact;
    source?: string;
};

type AccountIcpScoreExportCounts = {
    csvExportCount: number;
    salesforceExportCount: number;
    salesforceSandboxExportCount: number;
    hubspotExportCount: number;
};

type EnrichedContact = {
    id: string;
    firstName: string;
    lastName: string;
    title: string;
    emailConfidence: EmailConfidence;
    linkedInUrl: string;
    email: string;
    phone: string;
};

export type SampleContact = {
    id: string;
    firstName?: string;
    lastName?: string;
    title?: string;
    hasEmail?: boolean;
    hasPhone?: boolean;
    emailConfidence?: EmailConfidence;
    linkedInUrl?: string;
};

enum EmailConfidence {
    RISKY = 'risky',
    VALID = 'valid',
}
