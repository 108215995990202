import useNavigationTabs from 'components/hooks/useNavigationTabs';
import {AicpQueryProvider} from 'components/providers/AicpQueryProvider';
import type {NavigationTab} from 'components/types';
import {PrivatePage} from 'components/ui/page/PrivatePage';
import {SubPage} from 'components/ui/page/SubPage';
import {
    PAGE_ID_AICP_ANALYSIS_EXEGRAPHIC_LENSES,
    PAGE_ID_AICP_ANALYSIS_EXPLORATION,
    PAGE_ID_AICP_ANALYSIS_FIRMOGRAPHICS,
    PAGE_ID_AICP_ANALYSIS_LOOKALIKE_COMPANIES,
} from 'components/util/constants';
import {AICP_URL} from 'components/util/routeConstants';
import styled from 'styled-components';

export const AICP_ANALYSIS_PAGE_EXPLORATION_TAB_ROUTE = 'exploration';

const AicpAnalysisSubPageStyles = styled.div`
    .page-title {
        text-align: center;
    }

    @media (min-width: 1063px) {
        .page-title {
            text-align: left;
        }
    }
`;

export const aicpAnalysisNavigationTabs: NavigationTab[] = [
    {
        pageId: PAGE_ID_AICP_ANALYSIS_EXPLORATION,
        label: 'Exploration',
        route: AICP_ANALYSIS_PAGE_EXPLORATION_TAB_ROUTE,
    },
    {
        pageId: PAGE_ID_AICP_ANALYSIS_EXEGRAPHIC_LENSES,
        label: 'Exegraphics',
        route: 'exegraphic-lenses',
    },
    {
        pageId: PAGE_ID_AICP_ANALYSIS_FIRMOGRAPHICS,
        label: 'Firmographics',
        route: 'firmographics',
    },
    {
        pageId: PAGE_ID_AICP_ANALYSIS_LOOKALIKE_COMPANIES,
        label: 'Companies',
        route: 'companies/lookalike-companies',
    },
];

interface AicpAnalysisSubPageProps {
    pageId: string;
    aicpId: string;
    children?: React.FunctionComponent;
}

const AicpAnalysisSubPage = ({
    aicpId,
    children,
    pageId,
}: AicpAnalysisSubPageProps) => {
    const {navigationTabs, currentPageNavigationTab} = useNavigationTabs(
        aicpAnalysisNavigationTabs,
        pageId
    );

    return (
        <PrivatePage
            pageId={pageId}
            contentStyles={{padding: '0px'}}
            title={currentPageNavigationTab?.label}
        >
            {' '}
            <AicpAnalysisSubPageStyles>
                <AicpQueryProvider id={aicpId}>
                    {({aicp, isClientReady, queryProps}) => (
                        <SubPage
                            title={aicp.name}
                            navigationTabsBaseUrl={`${AICP_URL}/${aicpId}`}
                            navigationTabs={navigationTabs}
                            currentPageNavigationTab={currentPageNavigationTab}
                        >
                            {children &&
                                children({
                                    aicp,
                                    isClientReady,
                                    queryProps,
                                })}
                        </SubPage>
                    )}
                </AicpQueryProvider>
            </AicpAnalysisSubPageStyles>
        </PrivatePage>
    );
};

export default AicpAnalysisSubPage;
