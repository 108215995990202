import {useState} from 'react';
import {Button} from 'components/mui';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
    PopperContentProps,
    PopperProvider,
    PopperProviderControlProps,
} from 'components/ui/poppers/PopperProvider';
import AicpCardActionsMenuCurrentMenu from 'components/ui/pages/aicps/actions-menu/AicpCardActionsMenuCurrentMenu';
import Aicp from 'core/Aicp';

const AicpCardActionsMenu = (props: {aicp: Aicp; currentStatus: string}) => {
    const accountSuppressionLists = props.aicp?.accountSuppressionLists || [];
    const comparisonLists = props.aicp?.targetSources || [];
    const [currentSubMenu, setCurrentSubMenu] = useState(null);

    return (
        <PopperProvider
            flip
            preventOverflow
            placement="left-start"
            onClose={() => {
                setCurrentSubMenu(null);
            }}
            popperContent={({
                handleListKeyDown,
                isOpen,
            }: PopperContentProps) => {
                return (
                    <div>
                        <AicpCardActionsMenuCurrentMenu
                            isParentPopperOpen={isOpen}
                            popperHandleListKeyDown={handleListKeyDown}
                            currentSubMenu={currentSubMenu}
                            setCurrentSubMenu={setCurrentSubMenu}
                            accountSuppressionLists={accountSuppressionLists}
                            comparisonLists={comparisonLists}
                            {...props}
                        />
                    </div>
                );
            }}
        >
            {({
                isOpen,
                handleToggle,
                anchorRef,
            }: PopperProviderControlProps) => (
                <Button
                    variant={isOpen ? 'contained' : 'outlined'}
                    disableElevation
                    data-testid="actions-icon"
                    ref={anchorRef}
                    aria-controls={isOpen ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    Actions <ArrowDropDownIcon />
                </Button>
            )}
        </PopperProvider>
    );
};

export default AicpCardActionsMenu;
