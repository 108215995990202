import styled from 'styled-components';
import Router from 'next/router';
import PropTypes from 'prop-types';
import {CREATE_ICP_URL} from 'components/util/routeConstants';
import {Box, Typography, Button, Grid, Hidden} from 'components/mui';
import Image from 'next/legacy/image';

const StyledWrapper = styled.div`
    padding: 40px 36px 48px;
    text-align: center;

    .splash-background {
        height: 510px;
        overflow: hidden;
    }

    .splash-background-inner {
        position: relative;
        top: -70px;
    }
    @media (min-width: ${(props) => props.theme.mobileToDesktopBreakpoint}px) {
        padding: 40px 56px 48px;
    }
`;

const AicpSplash = ({isFirstIcp}) => {
    return (
        <StyledWrapper>
            <Grid container direction="column">
                <Grid item>
                    <Typography variant="h1">
                        Crushing pipeline and saving time.
                    </Typography>
                    <Box mt={3}>
                        <Typography variant="subtitle1">
                            Rev will create mathematical models of your ideal
                            customer profile and{' '}
                            <Hidden lgDown>
                                <br />
                            </Hidden>
                            allow you to customize and fine tune it for your
                            business.
                        </Typography>
                    </Box>

                    <div className="splash-background">
                        <div className="splash-background-inner">
                            <Image
                                className="splash-background-image"
                                src="/pages/aicp/splash.svg"
                                width="650"
                                height="650"
                            />
                        </div>
                    </div>
                    <Button
                        className="submit-button"
                        data-testid="create-aicp-button"
                        type="button"
                        size="large"
                        onClick={() => {
                            Router.push(CREATE_ICP_URL);
                        }}
                    >
                        {isFirstIcp ? 'Create My First aiCP' : 'Create an aiCP'}
                    </Button>
                </Grid>
            </Grid>
        </StyledWrapper>
    );
};

AicpSplash.propTypes = {
    isFirstIcp: PropTypes.bool,
};
AicpSplash.defaultProps = {
    isFirstIcp: false,
};

export default AicpSplash;
