import numeral from 'numeral';
import {countAlphabetCharactersInString} from './stringUtil';

export const getRandomNumber = (exclusiveMax: number): number => {
    return Math.floor(Math.random() * exclusiveMax);
};

export const getUniqueRandomNumbersList = (
    listSize: number,
    exclusiveMax: number
): number[] => {
    if (listSize > exclusiveMax) {
        throw new Error(
            '`exclusiveMax` must be greater or equal to `listSize`.'
        );
    }

    const randomNumbers = new Set<number>();

    while (randomNumbers.size !== listSize) {
        randomNumbers.add(Math.floor(Math.random() * exclusiveMax));
    }

    return Array.from(randomNumbers);
};

export const LargeNumberFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
});

export const USDFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    style: 'currency',
    currency: 'USD',
});

export function convertNumberAbbreviationToInteger(numberString: string) {
    if (/[a-zA-Z]/.test(numberString)) {
        if (countAlphabetCharactersInString(numberString) === 1) {
            numberString = ('' + numberString)
                .toUpperCase()
                .replace(/[^\w!.]/, '');
            let totalNumber = 0;
            let abbreviationValue = 0;
            let abbreviationLetter = '';

            const abbreviationValues = {
                K: 1000,
                M: 1000000,
                B: 1000000000,
                T: 1000000000000,
            };

            Object.keys(abbreviationValues).forEach((abbreviation) => {
                if (numberString.includes(abbreviation)) {
                    abbreviationValue = abbreviationValues[abbreviation];
                    abbreviationLetter = abbreviation;
                }
            });

            const multiplier = numberString.split(abbreviationLetter)[0];

            totalNumber = Number(multiplier) * abbreviationValue;

            return Number(totalNumber);
        }

        return numberString;
    }

    return Number(numberString);
}

export function convertIntegerToNumberAbbreviation(num: string) {
    let formattedNumber = num;

    if (!/[a-zA-Z]/.test(num)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        formattedNumber = numeral(num)
            .format('($0.00 a)')
            .replace(/\.00|\$|\s/g, '')
            .toUpperCase();
    }

    return formattedNumber;
}

export function convertLargeIntegerToRoundedAbbreviation(
    numInUniverse,
    decimalPlaces = 10
) {
    decimalPlaces = Math.pow(10, decimalPlaces);
    const numberCharacters = ['K', 'M', 'B', 'T'];
    for (let i = numberCharacters.length - 1; i >= 0; i--) {
        const size = Math.pow(10, (i + 1) * 3);
        if (size <= numInUniverse) {
            numInUniverse =
                Math.round((numInUniverse * decimalPlaces) / size) /
                decimalPlaces;
            if (numInUniverse === 1000 && i < numberCharacters.length - 1) {
                numInUniverse = 1;
                i++;
            }
            numInUniverse += numberCharacters[i];
            break;
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return numInUniverse;
}

const numberUtil = {
    convertNumberAbbreviationToInteger,
    convertIntegerToNumberAbbreviation,
    convertLargeIntegerToRoundedAbbreviation,
};

export default numberUtil;
