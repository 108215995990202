import {useAicpQuery} from 'components/hooks/useAicpQuery';
import PropTypes from 'prop-types';

const AicpQueryProvider = ({id, children}) => {
    const {aicp, isClientReady, isAicpActive, queryProps} = useAicpQuery(id);

    if (queryProps.loading || !isAicpActive) {
        return null;
    }

    return children({aicp, isClientReady, id, queryProps});
};

AicpQueryProvider.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.any,
};

export {AicpQueryProvider};
