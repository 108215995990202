import md5 from 'crypto-js/md5';

const emailRegex = /^[^@]+@[^.@]+(\.[^.@]+)+$/i;
const nonBusinessEmailRegex =
    /^((?!@aol.com|@gmail.com|@yahoo.com|@hotmail.com|@live.com|@icloud.com).)*$/i;

export const emailUtil = {
    regex: emailRegex,
    isValid: function isValid(emailAddress) {
        return emailRegex.test(emailAddress);
    },
    isBusinessEmail: function isBusinessEmail(emailAddress) {
        return nonBusinessEmailRegex.test(emailAddress);
    },
    buildContactHeader: function buildContactHeader(name, emailAddress) {
        if (!name) {
            return emailAddress;
        }

        return '"' + name.replace(/"/g, "'") + '" <' + emailAddress + '>'; // eslint-disable-line
    },
    getAvatarUrl: function getAvatarUrl(emailAddress, size) {
        emailAddress = emailAddress || '';
        size = size || 50;
        const emailHash = md5(emailAddress.toLowerCase()).toString();
        return (
            'https://gravatar.com/avatar/' + emailHash + '?s=' + size + '&d=mm'
        );
    },
};
