import {useState, useEffect} from 'react';
import {emailUtil} from 'components/util/emailUtil';
import {FieldData} from './FormStateProvider';

const MIN_CHARACTERS_REQUIRED_FOR_EMAIL = 4;
const FORMATTED_PHONE_NUMBER_CHARACTER_COUNT = '(000) 000-0000'.length;
const REQUIRED_REASON_TEXT = 'This field is required';

interface ValidationRule {
    rule: (value: unknown, formState?: FormState) => boolean;
    reason: string;
}

interface ValidationRules {
    [key: string]: ValidationRule[];
}

const VALIDATION_RULES: ValidationRules = {
    email: [
        {
            rule: emailUtil.isValid,
            reason: 'Does not look like a valid email address',
        },
        {
            rule: emailUtil.isBusinessEmail,
            reason: 'The email provided must be a business email',
        },
    ],
    tel: [
        {
            rule: (value: string) =>
                value.length === FORMATTED_PHONE_NUMBER_CHARACTER_COUNT,
            reason: 'A complete US phone number is required',
        },
    ],
    text: [
        {
            rule: (value: string) => value.length > 0,
            reason: 'This field is required',
        },
    ],
};

interface FormState {
    [fieldName: string]: FieldData;
}

interface FormValidationProviderProps {
    formState: FormState;
    children: (props: FormValidationChildrenProps) => JSX.Element;
    onlyValidateRequiredFields?: boolean;
}

interface FormValidationChildrenProps {
    getFieldInvalidReasons: (fieldName: string) => string[];
    isFieldInvalid: (fieldName: string) => boolean;
    isFieldValid: (fieldName: string) => boolean;
    isFormValid: boolean;
    setIsFormValid: (isValid: boolean) => void;
}

const isFileFieldFilled = (value: unknown): boolean => {
    if (
        typeof value === 'object' &&
        value !== null &&
        'name' in value &&
        'url' in value
    ) {
        const typedValue = value as {name: string; url: string};
        return typedValue.name !== '' && typedValue.url !== '';
    }
    return false;
};

const isEmailFieldFilled = (value: string): boolean =>
    value.length > MIN_CHARACTERS_REQUIRED_FOR_EMAIL &&
    value.includes('@') &&
    value.includes('.');

const isFieldFilledOut = (formField: FieldData): boolean => {
    const value = formField.value;
    switch (formField.validationRules) {
        case 'file':
            return isFileFieldFilled(value);
        case 'email':
            return isEmailFieldFilled(value as string);
        default:
            return value !== null && value !== undefined && value !== '';
    }
};

const FormValidationProvider: React.FC<FormValidationProviderProps> = ({
    formState,
    children,
    onlyValidateRequiredFields = true,
}) => {
    const [isFormValid, setIsFormValid] = useState(false);

    const isFieldValid = (fieldName: string): boolean => {
        const defaultValidationRules = formState[fieldName].validationRules;
        const value = formState[fieldName].value;

        if (!isFieldFilledOut(formState[fieldName])) {
            return false;
        }

        const fieldValidationRules =
            VALIDATION_RULES[defaultValidationRules] || [];
        return fieldValidationRules.every((validationRule) => {
            return validationRule.rule(value, formState);
        });
    };

    const isFieldInvalid = (fieldName: string): boolean => {
        if (!isFieldFilledOut(formState[fieldName])) {
            return false;
        }

        return !isFieldValid(fieldName);
    };

    const getFieldInvalidReasons = (fieldName: string): string[] => {
        const field = formState[fieldName];
        const value = field.value;
        const reasons: string[] = [];

        // Check if the field is required and empty
        if (!isFieldFilledOut(field) && field.isRequired) {
            reasons.push(REQUIRED_REASON_TEXT);
            return reasons; // Return early if the field is empty
        }

        // Get the validation rules for the field
        const validationRules = VALIDATION_RULES[field.validationRules] || [];

        // Check each validation rule
        validationRules.forEach((validationRule) => {
            if (!validationRule.rule(value, formState)) {
                reasons.push(validationRule.reason);
            }
        });

        return reasons;
    };

    useEffect(() => {
        const isValid = Object.keys(formState).every((fieldName) => {
            const field = formState[fieldName];
            const meetsFilledOutRequirement =
                !field.isRequired || isFieldFilledOut(field);

            return (
                (onlyValidateRequiredFields && !field.isRequired) ||
                (meetsFilledOutRequirement && isFieldValid(fieldName))
            );
        });

        setIsFormValid(isValid);
    }, [formState, onlyValidateRequiredFields]);

    return children({
        getFieldInvalidReasons,
        isFieldInvalid,
        isFieldValid,
        isFormValid,
        setIsFormValid,
    });
};

export {FormValidationProvider, VALIDATION_RULES, REQUIRED_REASON_TEXT};
