import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    FormControl,
    IconButton,
    Stack,
    TextField,
    Typography,
} from 'components/mui';
import {MINIMUM_COMPARISON_LIST_CSV_ROWS} from 'core/Aicp/constants';
import {FileUpload} from 'components/ui/forms/file-upload/FileUpload';
import {FormStateProvider} from 'components/providers/FormStateProvider';
import {FormValidationProvider} from 'components/providers/FormValidationProvider';
import {Notification} from 'core/Notification/index.ts';
import styled from 'styled-components';
import {comparisonListFormFields} from './form-fields/formFields';
import {AddComparisonListMenuPropTypes} from './prop-types/propTypes';

const AddComparisonListMenuContainer = styled(Box)`
    width: 450px;
    .container-select {
        margin-bottom: 5px;
    }
    h5 {
        font-size: 1.6rem;
        font-weight: ${({theme}) => theme.typography.fontWeightBold};
    }
    h6 {
        font-size: 1.5rem;
        font-weight: ${({theme}) => theme.typography.fontWeightBold};
    }
`;

const AddComparisonListMenuContent = styled(Box)`
    padding: 10px 30px 32px 30px;
    display: flex;
    flex-direction: column;
    gap: ${({theme}) => theme.spacing(2)};
`;

const AddComparisonListMenu = ({
    aicpName,
    aicpId,
    setCurrentSubMenu,
    addComparisonListToAicp,
    isAddComparisonDisabled,
}) => {
    const setFileName = (fileName, newValue) => {
        if (!fileName.value) {
            return newValue.name;
        }
        return fileName.value;
    };

    const cancelHandler = () => {
        setCurrentSubMenu(null);
    };

    return (
        <FormStateProvider initialFields={comparisonListFormFields}>
            {({
                formState,
                onInputChange,
                setFieldData,
                initialFields,
                updateMultiSelectValue,
                isMultiSelectOptionSelected,
                addField,
            }) => {
                const {fileName, comparisonFile} = formState;
                return (
                    <FormValidationProvider formState={formState}>
                        {({isFormValid, getFieldInvalidReasons}) => {
                            return (
                                <AddComparisonListMenuContainer data-testid="add-comparison-list-menu-action">
                                    <Box
                                        p={2.5}
                                        pr={3}
                                        pb={1.25}
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <Box display="flex" flexGrow={1}>
                                            <Typography variant="h5">
                                                Add New Comparison List
                                            </Typography>
                                        </Box>

                                        <IconButton
                                            aria-label="close"
                                            size="small"
                                            color="secondary"
                                            sx={{
                                                position: 'absolute',
                                                right: 8,
                                                top: 8,
                                            }}
                                            onClick={cancelHandler}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                    <hr />
                                    <AddComparisonListMenuContent>
                                        <Box width="100%">
                                            <form data-testid="form-comparison-list">
                                                <Box mb={2}>
                                                    <FormControl
                                                        variant="standard"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <TextField
                                                            data-testid="comparison-list-name-input"
                                                            label="File Name *"
                                                            onChange={onInputChange(
                                                                'fileName'
                                                            )}
                                                            value={
                                                                fileName.value
                                                            }
                                                            variant="outlined"
                                                            inputProps={{
                                                                'data-hj-allow': true,
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Box>
                                                <FileUpload
                                                    file={comparisonFile.value}
                                                    minNumberOfDataRows={
                                                        MINIMUM_COMPARISON_LIST_CSV_ROWS
                                                    }
                                                    onComplete={(value) => {
                                                        setFieldData({
                                                            fieldName:
                                                                'comparisonFile',
                                                            data: {
                                                                value,
                                                            },
                                                        });

                                                        fileName.value =
                                                            setFileName(
                                                                fileName,
                                                                value
                                                            );
                                                    }}
                                                    onRemove={() => {
                                                        setFieldData({
                                                            fieldName:
                                                                'comparisonFile',
                                                            data: {
                                                                value: null,
                                                            },
                                                        });
                                                    }}
                                                    onError={(e) => {
                                                        Notification.error(e);
                                                    }}
                                                    requiredHeaders={[
                                                        'website',
                                                    ]}
                                                    uploadDirective="icpTargetListFile"
                                                    dragAndDropFileUploadId={
                                                        'comparison-list-file-upload'
                                                    }
                                                    convertWebsiteColumnTo={
                                                        'website'
                                                    }
                                                    additionalInstructionText="* Additional user-defined columns will be included in future data exports."
                                                    titleText="Upload a comparison list"
                                                    sampleFileHref="/sample-files/sample-company-list-with-additional-columns.csv"
                                                    sampleFileName="sample-comparison-list.csv"
                                                />
                                            </form>
                                        </Box>

                                        <Stack
                                            direction="row"
                                            spacing={1}
                                            justifyContent="flex-end"
                                        >
                                            <Button
                                                data-testid="button-cancel-add-comparison-file"
                                                variant="outlined"
                                                onClick={cancelHandler}
                                            >
                                                CANCEL
                                            </Button>

                                            <Button
                                                data-testid="submit-comparison-list-button"
                                                type="button"
                                                size="medium"
                                                disabled={
                                                    !comparisonFile?.value ||
                                                    isAddComparisonDisabled
                                                }
                                                onClick={() => {
                                                    addComparisonListToAicp(
                                                        comparisonFile.value,
                                                        fileName.value
                                                    );
                                                }}
                                            >
                                                Add Comparison List
                                            </Button>
                                        </Stack>
                                    </AddComparisonListMenuContent>
                                </AddComparisonListMenuContainer>
                            );
                        }}
                    </FormValidationProvider>
                );
            }}
        </FormStateProvider>
    );
};
AddComparisonListMenu.propTypes = AddComparisonListMenuPropTypes;

export default AddComparisonListMenu;
