import {useState, useEffect} from 'react';
import {useLazyQuery} from '@apollo/client';
import AICP from 'graphql/pages/aicp/aicp.graphql';
import {AICP_STATUS} from 'components/ui/pages/aicps/statuses.js';
import {logger} from 'utils/logger';
import Aicp from 'core/Aicp';
import {CompanySourceStatus} from 'core/Aicp/types';

const AICP_POLL_INTERVAL = 30000;

const hasProcessingTargetSources = (aicp: Aicp) => {
    return (
        aicp?.targetSources?.filter(
            (targetSource) =>
                targetSource?.status === CompanySourceStatus.PROCESSING
        )?.length > 0
    );
};

function useAicpQuery(aicpId: string) {
    const [isClientReady, setIsClientReady] = useState(false);
    const [aicp, setAicp] = useState<Aicp>(null);
    const [isPolling, setIsPolling] = useState(false);

    const [getAicp, {data, loading, error, startPolling, stopPolling}] =
        useLazyQuery(AICP, {
            pollInterval: AICP_POLL_INTERVAL,
        });

    const idealCustomerProfile =
        data?.idealCustomerProfile?.idealCustomerProfile;
    const isAicpActive = aicp && aicp.status === AICP_STATUS.active;

    useEffect(() => {
        if (!isClientReady) {
            setIsClientReady(true);
            getAicp({
                variables: {idealCustomerProfileId: aicpId},
            }).catch(() => null);
        }
    }, [isClientReady]);

    useEffect(() => {
        if (idealCustomerProfile) {
            setAicp(idealCustomerProfile);
            if (hasProcessingTargetSources(aicp) && !isPolling) {
                startPolling(AICP_POLL_INTERVAL);
                setIsPolling(true);
            } else if (!hasProcessingTargetSources(aicp) && isPolling) {
                setIsPolling(false);
                stopPolling();
            }
        }
    }, [idealCustomerProfile]);

    if (error) {
        logger.error(`Error retrieving AICP with ID ${aicpId}`);
    }

    return {
        aicp,
        isClientReady,
        isAicpActive,
        queryProps: {data, loading, error},
    };
}

export {useAicpQuery};
