import {ApolloQueryResult} from '@apollo/client';
import AicpCard from 'components/ui/pages/aicps/aicp-card/AicpCard';
import getClientOrgTags from 'components/util/getClientOrgTags';
import Aicp from 'core/Aicp';

interface AicpCardsProps {
    aicps: Aicp[];
    refetchAicps: (variables?: unknown) => Promise<ApolloQueryResult<unknown>>;
}

const AicpCards = ({aicps, refetchAicps}: AicpCardsProps) => {
    return (
        <>
            {aicps.map((aicp) => (
                <AicpCard
                    key={aicp.id}
                    aicp={aicp}
                    clientOrgTags={getClientOrgTags(aicps)}
                    refetchAicps={refetchAicps}
                />
            ))}
        </>
    );
};

export default AicpCards;
