import {AICP_STATUS} from 'components/ui/pages/aicps/statuses.js';

const getActiveIcps = (aicps = []) => {
    if (aicps.length > 0) {
        return aicps.filter((aicp) => aicp.status === AICP_STATUS.active);
    }
    return [];
};

export default getActiveIcps;
