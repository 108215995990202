import styled from 'styled-components';
import {
    MenuItem,
    Typography,
    Box,
    CircularProgress,
    Stack,
    IconButton,
} from 'components/mui';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledMenuItem = styled(MenuItem)`
    cursor: auto;
`;

const StyledListHeader = styled(Typography)`
    color: ${({theme}) => theme.palette.primary.main};
    font-weight: bold;

    &:hover {
        color: ${({theme}) => theme.palette.primary.main};
    }
`;

const ListName = styled(Typography)`
    font-weight: 600;
`;

interface DisplayFileItemProps {
    fileName: string;
    headerName?: string;
    onDelete: () => void;
    showDeleteIcon?: boolean;
    processing?: boolean;
}

const DisplayFileItem = ({
    fileName,
    headerName = 'Company',
    onDelete = () => null,
    showDeleteIcon = true,
    processing = false,
}: DisplayFileItemProps) => {
    return (
        <StyledMenuItem
            data-testid="display-file-component"
            disableRipple={true}
            style={{
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <Box
                flexDirection="column"
                justifyContent="flex-end"
                alignItems="center"
                gap={2}
            >
                <Stack direction={'row'}>
                    <StyledListHeader component="div">
                        {headerName}
                    </StyledListHeader>
                    {processing && (
                        <Box
                            pl={1}
                            alignContent={'center'}
                            alignSelf={'center'}
                        >
                            <CircularProgress size={15} />
                        </Box>
                    )}
                </Stack>
                <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="265px"
                >
                    <Box
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                    >
                        <ListName component="div" noWrap>
                            {fileName}
                        </ListName>
                    </Box>

                    {showDeleteIcon && (
                        <IconButton
                            onClick={onDelete}
                            data-testid="delete-file-icon"
                        >
                            <DeleteIcon
                                cursor="pointer"
                                fontSize="small"
                                color={'secondary'}
                            />
                        </IconButton>
                    )}
                </Box>
            </Box>
        </StyledMenuItem>
    );
};

export default DisplayFileItem;
