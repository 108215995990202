import {MenuList as MuiMenuList} from 'components/mui';
import styled from 'styled-components';

const MenuList = styled(MuiMenuList)`
    width: auto;
    box-shadow: 2px 2px 8px rgba(45, 54, 64, 0.3);
    .menu-subheader {
        color: ${({theme}) => theme.palette.secondary.dark};
        font-weight: ${({theme}) => theme.typography.fontWeightBold};
        margin-bottom: 5px;
    }
    .menu-item {
        color: ${({theme}) => theme.palette.secondary.main};
        font-weight: ${({theme}) => theme.typography.fontWeightSemibold};
        &:hover {
            color: ${({theme}) => theme.palette.action.hover};
        }
    }
    .menu-item-disabled {
        color: ${({theme}) => theme.palette.secondary.light};
        font-weight: ${({theme}) => theme.typography.fontWeightSemibold};
        &:hover {
            color: ${({theme}) => theme.palette.action.hover};
        }
    }
    .menu-sub-item {
        color: ${({theme}) => theme.palette.primary.main};
        font-weight: ${({theme}) => theme.typography.fontWeightSemibold};
        &:hover {
            color: ${({theme}) => theme.palette.action.hover};
        }
    }
    .MuiMenuItem-root {
        background-color: transparent;
        &.Mui-focusVisible {
            background-color: ${({theme}) => theme.palette.background.default};
        }
        &:selected {
            color: ${({theme}) => theme.palette.action.hover};
        }
        &.menu-title.Mui-disabled {
            opacity: 1;
        }
    }
`;

type StyledActionsMenuList = {
    autoFocusItem: boolean;
    onKeyDown?: () => void;
    children: React.ReactNode;
};

export default function StyledActionsMenuList({
    autoFocusItem,
    onKeyDown,
    children,
    ...props
}: StyledActionsMenuList) {
    return (
        <MenuList
            autoFocusItem={autoFocusItem}
            onKeyDown={onKeyDown}
            {...props}
        >
            {children}
        </MenuList>
    );
}
