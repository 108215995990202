import {useMutation} from 'graphql/client';
import AICP from 'graphql/pages/aicp/aicp.graphql';
import REMOVE_COMPARISON_LIST_FROM_ICP from 'graphql/pages/aicp/removeComparisonListFromIcp.graphql';
import {ResponseStatus} from 'core/GraphQL/types';

export type RemoveComparisonListFromIcpData = {
    status: ResponseStatus;
};

export type RemoveComparisonListFromIcpVariables = {
    icpId: string;
    comparisonListId: string;
};

export default function useRemoveComparisonListFromIcp(
    aicpId: string,
    onSuccessCallback: () => void,
    onErrorCallback: () => void
) {
    const [executeMutation, {loading}] = useMutation<
        RemoveComparisonListFromIcpData,
        RemoveComparisonListFromIcpVariables
    >(REMOVE_COMPARISON_LIST_FROM_ICP, {
        refetchQueries: [
            {query: AICP, variables: {idealCustomerProfileId: aicpId}},
        ],
        onCompleted() {
            if (onSuccessCallback) onSuccessCallback();
        },
        onError() {
            if (onErrorCallback) onErrorCallback();
        },
    });

    const removeComparisonListFromIcp = (comparisonListId) => {
        return executeMutation({
            variables: {
                icpId: aicpId,
                comparisonListId: comparisonListId,
            },
        });
    };

    return {
        removeComparisonListFromIcp,
        loading,
    };
}
