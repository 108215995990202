import {isEmpty} from 'lodash';
import {MenuItem, Typography} from 'components/mui';
import DisplayFileItem from '../DisplayFileItem';
import {CsvCompanySource} from 'core/Aicp/types';
import {RemoveAccountSuppressionListFromIcpData} from '../hooks/useRemoveAccountSuppressionListFromIcp';
import {Logger} from 'utils/logger';
import {logErrorMessages} from 'utils/graphqlResponse';
import {FetchResult} from '@apollo/client';

const logger = new Logger('SuppressionContent');

interface SuppressionContentProps {
    accountSuppressionLists: CsvCompanySource[];
    removeAccountSuppressionListFromIcp: (
        accountSuppressionListId: string
    ) => Promise<FetchResult<RemoveAccountSuppressionListFromIcpData>>;
}

const SuppressionContent = ({
    accountSuppressionLists,
    removeAccountSuppressionListFromIcp,
}: SuppressionContentProps) => {
    if (isEmpty(accountSuppressionLists)) {
        return <></>;
    }

    return (
        <div>
            <hr />
            <MenuItem disabled>
                <Typography
                    component="span"
                    className="menu-subheader"
                    data-testid="header-suppression"
                >
                    Suppression
                </Typography>
            </MenuItem>

            {accountSuppressionLists?.map((accountSuppressionList) => (
                <DisplayFileItem
                    key={accountSuppressionList.id}
                    fileName={accountSuppressionList.uploadedFile.name}
                    onDelete={() => {
                        removeAccountSuppressionListFromIcp(
                            accountSuppressionList.id
                        )?.catch((error) => logErrorMessages(error, logger));
                    }}
                />
            ))}
        </div>
    );
};

export default SuppressionContent;
