/**
 * This hook encapsulates the logic needed for a simple state machine. Transitioning
 * from one state to another is achieved by dispatching events. If the current state
 * has a transition defined for a dispatched event, then the state machine will
 * transition to a new state and optionally perform an action. Otherwise, the event
 * is ignored.
 *
 * Usage:
 *
 *  const Widget = () => {
 *      const [state, dispatchEvent] = useStateMachine({
 *          initialState: 'loading',
 *          states: {
 *              loading: {
 *                  dataReceived: {
 *                      newState: displayingData,
 *                      action(data) {
 *                          logger.debug(`Received data ${data}`);
 *                      }
 *                  },
 *                  errorOccurred: {
 *                      newState: displayingError,
 *                      action(error) {
 *                          logger.error(`Error occurred while loading data: ${error}`);
 *                      }
 *                  }
 *              },
 *              displayingError: {
 *                  reload: {
 *                      newState: loading
 *                  }
 *              }
 *          }
 *      });
 *      const [data, reloadData] = useLoadData({
 *          onComplete(data) {
 *              dispatchEvent('dataReceived', data);
 *          },
 *          onError(error) {
 *               dispatchEvent('errorOccurred', error);
 *          }
 *      })
 *
 *      if (state === 'loading') {
 *          return <Loading />;
 *      }
 *      if (state === 'displayingData') {
 *          return <DataDisplay data={data} />;
 *      }
 *      if (state === 'displayingError') {
 *          const reload = () => {
 *              dispatchEvent('reload');
 *              reloadData();
 *          }
 *          return <Error reload={reload} />;
 *      }
 *  };
 */

import {useState} from 'react';
import {Logger} from 'utils/logger';

const logger = new Logger('useStateMachine');

type State = string | number;
type Event = string | number;
type EventArgs = unknown[];

export interface StateMachineSpec {
    initialState: State;
    states: {
        [state: State]: {
            [event: Event]: {
                newState: State;
                action?: (...eventArgs: EventArgs) => void;
            };
        };
    };
}

type DispatchEvent = (event: Event, ...eventArgs: EventArgs) => void;

export default function useStateMachine(
    machineSpec: StateMachineSpec
): [State, DispatchEvent] {
    const [state, setState] = useState(machineSpec.initialState);

    const dispatchEvent: DispatchEvent = (event, ...eventArgs) => {
        logger.info(`Received event ${event} while in state ${state}`);
        const transition = machineSpec.states[state]?.[event];
        if (transition) {
            logger.info(`${state} -> ${transition.newState}`);
            setState(transition.newState);
            transition.action?.(...eventArgs);
        } else {
            logger.error(
                `State ${state} does not have a transition for event ${event}`
            );
        }
    };

    return [state, dispatchEvent];
}
