import {useCallback, useEffect, useState} from 'react';
import Aicp from 'core/Aicp';
import {CompanySourceStatus} from 'core/Aicp/types';
import {Notification} from 'core/Notification/index';
import AICP_COMPANY_SOURCE_STATUS_QUERY from 'graphql/pages/aicp/AicpCompanySourceStatus.graphql';
import {ResponseStatus} from 'src/types/graphql-types';
import {Logger} from 'utils/logger';
import {ApolloQueryResult, useLazyQuery} from '@apollo/client';

const logger = new Logger('useAicpCompanySourceStatusPollingQuery');

export const AICP_COMPANY_SOURCES_STATUS_POLL_INTERVAL = 30000;

interface CompanySourcesStatusVariables {
    idealCustomerProfileId: string;
}

interface CompanySourcesStatusPayload {
    status: ResponseStatus;
    idealCustomerProfile: Partial<Aicp>;
}
interface CompanySourcesStatusResponse {
    idealCustomerProfile: CompanySourcesStatusPayload;
}
interface UseAicpCompanySourceStatusPollingQueryProps {
    aicp: Aicp;
    refetchData: (variables?: unknown) => Promise<ApolloQueryResult<unknown>>;
}

const hasNotProcessingTargetSources = ({
    idealCustomerProfile,
}: {
    idealCustomerProfile: Partial<Aicp>;
}): boolean => {
    const processingTargetSources =
        idealCustomerProfile?.targetSources?.filter(
            (targetSource) =>
                targetSource?.status === CompanySourceStatus.PROCESSING
        )?.length ?? 0;
    return processingTargetSources === 0;
};

export default function useAicpCompanySourceStatusPollingQuery({
    aicp,
    refetchData,
}: UseAicpCompanySourceStatusPollingQueryProps) {
    const shouldPoll = !hasNotProcessingTargetSources({
        idealCustomerProfile: aicp,
    });

    const [isPolling, setIsPolling] = useState(false);

    const [executeQuery, {data, loading, error, stopPolling}] = useLazyQuery<
        CompanySourcesStatusResponse,
        CompanySourcesStatusVariables
    >(AICP_COMPANY_SOURCE_STATUS_QUERY, {
        pollInterval: AICP_COMPANY_SOURCES_STATUS_POLL_INTERVAL,
        variables: {idealCustomerProfileId: aicp.id},
        notifyOnNetworkStatusChange: true,
    });

    const processData = useCallback(() => {
        if (!isPolling) {
            executeQuery().catch((e) =>
                logger.error('Failed execure query', e)
            );
            setIsPolling(true);
        }
        if (data && loading === false && isPolling === true) {
            if (hasNotProcessingTargetSources(data.idealCustomerProfile)) {
                stopPolling();
                refetchData().catch((e) =>
                    logger.error('Failed to refetch data', e)
                );
                Notification.success('Comparison lists succesfully created', {
                    removeAfter: 5,
                });
                setIsPolling(false);
            }
        }
    }, [data, loading, stopPolling, refetchData, isPolling]);

    useEffect(() => {
        if (!shouldPoll) {
            stopPolling();
        } else if (error) {
            stopPolling();
            logger.error(
                `Error retrieving company source status  for  AICP with   ID ${aicp.id}`
            );
        } else {
            processData();
        }
    }, [data, stopPolling, refetchData, shouldPoll]);

    return {data};
}
