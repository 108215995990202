export const AICP_STATUS = {
    failed: 'FAILED',
    processing: 'PROCESSING',
    removed: 'REMOVED',
    active: 'ACTIVE',
    archived: 'ARCHIVED',
    updating: 'UPDATING',
};

export const AICP_STATUS_ICON = {
    failed: '/pages/aicp/icon-notify-error.svg',
    processing: '/pages/aicp/icon-loading-green.svg',
};
