import PropTypes from 'prop-types';
import {Box} from 'components/mui';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import {PopperProvider} from 'components/ui/poppers/PopperProvider';
import {UpdateAicpTagsMenu} from '../UpdateAicpTagsMenu';

export default function EditTagsButton({aicpId, aicpTags, clientOrgTags}) {
    return (
        <PopperProvider
            flip
            preventOverflow
            placement="right"
            arrow={false}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            popperContent={({handleClose, setIsOpen}) => {
                return (
                    <UpdateAicpTagsMenu
                        aicpId={aicpId}
                        existingAicpTags={aicpTags}
                        clientOrgTags={clientOrgTags}
                        handleClose={handleClose}
                        setIsOpen={setIsOpen}
                    />
                );
            }}
        >
            {({handleToggle, anchorRef}) => {
                return (
                    <Box ref={anchorRef} aria-haspopup="true">
                        {aicpTags.length > 0 ? (
                            <EditIcon
                                data-testid="edit-tags-icon"
                                onClick={handleToggle}
                                cursor="pointer"
                                fontSize="small"
                                color="secondary"
                            />
                        ) : (
                            <AddIcon
                                data-testid="add-tags-icon"
                                onClick={handleToggle}
                                cursor="pointer"
                                fontSize="small"
                                color="secondary"
                            />
                        )}
                    </Box>
                );
            }}
        </PopperProvider>
    );
}

EditTagsButton.propTypes = {
    aicpId: PropTypes.string,
    aicpTags: PropTypes.arrayOf(PropTypes.string),
    clientOrgTags: PropTypes.arrayOf(PropTypes.string),
};
