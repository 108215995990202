import {useState} from 'react';
import Image from 'next/legacy/image';
import {Box, Typography} from 'components/mui';
import {DragAndDropArea} from './DragAndDropArea';
import {useBoolean} from 'utils/hooks/useBoolean';
import {UploadedFileDisplay} from 'components/ui/forms/file-upload/UploadedFileDisplay';
import FileUploadDialog from 'components/ui/dialogs/FileUploadDialog';
import {UploadedFileData} from 'components/providers/FileUploadProvider';

interface FileUploadProps {
    dragAndDropFileUploadId: string;
    onError: (errorMessage: string) => void;
    onComplete: (fileData: UploadedFileData) => void;
    onRemove: () => void;
    maxNumberOfDataRows?: number;
    minNumberOfDataRows?: number;
    requiredHeaders: string[];
    additionalInstructionText?: string;
    border: string;
    titleText: string;
    sampleFileHref?: string;
    sampleFileName?: string;
    convertWebsiteColumnTo: string;
    uploadDirective: string;
    file: UploadedFileData;
}

const FileUpload = ({
    dragAndDropFileUploadId,
    border,
    onError,
    onComplete,
    onRemove,
    maxNumberOfDataRows,
    minNumberOfDataRows,
    requiredHeaders,
    additionalInstructionText,
    titleText,
    sampleFileHref,
    sampleFileName,
    convertWebsiteColumnTo,
    uploadDirective,
    file,
}: FileUploadProps) => {
    const [uploadedFile, setUploadedFile] = useState(file);

    const {
        value: isFileUploadDialogOpen,
        setTrue: openFileUploadDialog,
        setFalse: closeFileUploadDialog,
    } = useBoolean(false);

    const onFileRemove = () => {
        onRemove();
        setUploadedFile(null);
    };

    if (uploadedFile) {
        return (
            <UploadedFileDisplay
                border={border}
                fileName={uploadedFile.name}
                onRemove={onFileRemove}
            />
        );
    }

    return (
        <DragAndDropArea className="container" border={border}>
            <Box
                className="border-area"
                onClick={openFileUploadDialog}
                data-testid={`file-upload-${dragAndDropFileUploadId}`}
            >
                <Image
                    src={
                        '/components/forms/file-upload/upload-secondary-icon.svg'
                    }
                    height="44"
                    width="70"
                    alt="upload"
                />
                <Box className="text-wrapper">
                    <Typography variant="subtitle1">
                        Click to <strong>Upload</strong> a File
                    </Typography>
                </Box>
            </Box>
            <FileUploadDialog
                isOpen={isFileUploadDialogOpen}
                closeDialog={closeFileUploadDialog}
                additionalInstructionText={additionalInstructionText}
                titleText={titleText}
                sampleFileHref={sampleFileHref}
                sampleFileName={sampleFileName}
                maxNumberOfDataRows={maxNumberOfDataRows}
                minNumberOfDataRows={minNumberOfDataRows}
                requiredHeaders={requiredHeaders}
                uploadedFile={uploadedFile}
                onUploadComplete={(value: UploadedFileData) => {
                    closeFileUploadDialog();
                    setUploadedFile(value);
                    onComplete(value);
                }}
                onFileRemove={() => {
                    setUploadedFile(null);
                    onRemove();
                }}
                onUploadError={(e) => onError(e)}
                uploadDirective={uploadDirective}
                border={border}
                dragAndDropFileUploadId={dragAndDropFileUploadId}
                convertWebsiteColumnTo={convertWebsiteColumnTo}
            />
        </DragAndDropArea>
    );
};

export {FileUpload};
