import PropTypes from 'prop-types';
import {} from 'components/ui/pages/aicps/statuses.js';

export const AddSuppressionMenuPropTypes = {
    aicpName: PropTypes.string.isRequired,
    aicpId: PropTypes.string.isRequired,
    setCurrentSubMenu: PropTypes.func.isRequired,
    addSuppressionListToAicp: PropTypes.func.isRequired,
    isSuppressionDisabled: PropTypes.bool.isRequired,
};
export const ComparisonListContentPropTypes = {
    comparisonLists: PropTypes.array,
    removeComparisonListFromIcp: PropTypes.func.isRequired,
};

export const DisplayFileItemPropTypes = {
    fileName: PropTypes.string.isRequired,
    headerName: PropTypes.string,
    onDelete: PropTypes.func,
    showDeleteIcon: PropTypes.bool,
};

export const AddComparisonListMenuPropTypes = {
    aicpName: PropTypes.string.isRequired,
    aicpId: PropTypes.string.isRequired,
    setCurrentSubMenu: PropTypes.func.isRequired,
    addComparisonListToAicp: PropTypes.func.isRequired,
    isAddComparisonDisabled: PropTypes.bool.isRequired,
};
